import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import { UserContext } from "../context/UserContext";
import MobileMenu from "../components/common/MobileMenu";
import MiInformacion from "./MiInformacion";
import MisMetodos from "./MisMetodos";
import NavbarCertification from "../components/certification/NavbarCertification";
import Videos from "./certification/Videos";

const PanelCertificacion = () => {
  const { user, signOut, getUsuario } = useContext(UserContext);

  useEffect(() => {
    getUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid px-0">
      <MobileMenu signOut={signOut} user={user} />
      <div className="sidebar">
        <NavbarCertification user={user} signOut={signOut} />
      </div>
      <div className="main-panel px-4">
        <div className="content overflow-x-hidden">
          <Router>
            <Videos path="/videos" default />
            <MiInformacion path="/informacion" />
            <MisMetodos path="/metodos" />
          </Router>
        </div>
      </div>
    </div>
  );
};

export default PanelCertificacion;
