/**
 * User
 */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_CREATED = "USER_CREATED";
export const SET_PROPERTY_USER = "SET_PROPERTY_USER";
export const SET_PROPERTY_LOGIN = "SET_PROPERTY_LOGIN";
export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const GUARDAR_USUARIO = "GUARDAR_USUARIO";
export const CUENTA_RECIBIDA = "CUENTA_RECIBIDA";
export const REATTEMPT_LOGIN = "REATTEMPT_LOGIN";
/**
 * Modal
 */
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SET_RESPONSE = "SET_RESPONSE";
export const CLEAR = "CLEAR";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const MODAL_COMPONENT = "MODAL_COMPONENT";
export const SHOW_ALERT = "SHOW_ALERT";
export const SHOW_SUCCESS = "SHOW_SUCCESS";
/**
 * Spinners
 */
export const HIDE_SPINNER = "HIDE_SPINNER";
export const SHOW_SPINNER = "SHOW_SPINNER";
/**
 * Cursos
 */
export const COURSES_RECIBIDOS = "COURSES_RECIBIDOS";
export const SINGLE_COURSE_RECIBIDO = "SINGLE_COURSE_RECIBIDO";
export const SET_PROPERTY_COURSE = "SET_PROPERTY_COURSE";
export const CREATE_COURSE = "CREATE_COURSE";
/**
 * Sections
 */
export const SECTIONS_RECIBIDAS = "SECTIONS_RECIBIDAS";
export const SINGLE_SECTION_RECIBIDA = "SINGLE_SECTION_RECIBIDA";
export const SET_PROPERTY_SECTION = "SET_PROPERTY_SECTION";
export const CREATE_SECTION = "CREATE_SECTION";
/**
 * Checkout
 */
export const SET_PAQUETE = "SET_PAQUETE";
export const SET_DISCOUNT_CODE = "SET_DISCOUNT_CODE";
export const SET_PAYMENT_SOURCE = "SET_PAYMENT_SOURCE";

/**
 * Metodos Pago
 */
export const METODOS_RECIBIDOS = "METODOS_RECIBIDOS";
/**
 * Ordenes
 */
export const ORDENES_RECIBIDAS = "ORDENES_RECIBIDAS";
export const SET_ORDEN = "SET_ORDEN";
/**
 * Descuentos
 */
export const DESCUENTOS_RECIBIDOS = "DESCUENTOS_RECIBIDOS";
export const SET_PROPERTY_DESCUENTO = "SET_PROPERTY_DESCUENTO";
export const CREATE_DESCUENTO = "CREATE_DESCUENTO";
export const SET_DESCUENTO = "SET_DESCUENTO";
export const SHOW_SPINNER_DESCUENTO = "SHOW_SPINNER_DESCUENTO";
export const HIDE_SPINNER_DESCUENTO = "HIDE_SPINNER_DESCUENTO";
/**
 * Purchases
 */
export const SET_PURCHASE = "SET_PURCHASE";
export const PURCHASES_RECEIVED = "PURCHASES_RECEIVED";
export const COURSE_PURCHASES_RECEIVED = "COURSE_PURCHASES_RECEIVED";
/**
 * Filters
 */
export const SET_FILTER = "SET_FILTER";
export const SET_FILTER_VALUES = "SET_FILTER_VALUES";
