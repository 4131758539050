import React, { createContext, useContext, useReducer } from "react";
import PurchasesReducer from "../reducers/PurchasesReducer";
import CoursePurchasesService from "../services/CoursePurchasesService";
import PurchasesService from "../services/PurchasesService";
import {
  COURSE_PURCHASES_RECEIVED,
  PURCHASES_RECEIVED,
  SET_PURCHASE,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  course_purchases: null,
  purchaes: null,
  purchase: null,
};

export const PurchasesContext = createContext(initialState);

export const PurchasesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PurchasesReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const getSinglePurchase = (purchase_id) => {
    PurchasesService.getSinglePurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_PURCHASE, payload: purchase });
    });
  };

  const getPurchases = () => {
    PurchasesService.getPurchases().then((res) => {
      const { purchases } = res.data;
      dispatch({ type: PURCHASES_RECEIVED, payload: purchases });
    });
  };

  const getCoursePurchases = () => {
    CoursePurchasesService.getCoursePurchases().then((res) => {
      const { courses } = res.data;
      dispatch({ type: COURSE_PURCHASES_RECEIVED, payload: courses });
    });
  };

  const cancelPurchase = (purchase_id, cancel_reason) => {
    PurchasesService.cancelPurchase(purchase_id, cancel_reason)
      .then(() => {
        hideModal();
        getPurchases();
      })
      .catch(alert);
  };

  return (
    <PurchasesContext.Provider
      value={{
        ...state,
        getPurchases,
        getCoursePurchases,
        getSinglePurchase,
        cancelPurchase,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
