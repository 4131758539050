import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import CoursePurchasesTable from "../components/memberships/CoursePurchasesTable";

const CourseAccesses = () => {
  const { course_purchases, getCoursePurchases } = useContext(PurchasesContext);

  useEffect(() => {
    getCoursePurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCourses = () => {
    if (Array.isArray(course_purchases)) {
      return <CoursePurchasesTable courses={course_purchases} />;
    }
  };
  return (
    <div className="container-fluid pt-3">
      <div className="mx-0 row align-items-center mb-3">
        <div className="container-fluid px-0">
          <h4>Mis Accesos</h4>
        </div>
      </div>
      {renderCourses()}
    </div>
  );
};

export default CourseAccesses;
