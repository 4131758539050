import React from "react";
import { S3_ENDPOINT } from "../../utils";

const SectionThumbnail = ({ name, file, icon }) => {
  const renderContent = () => {
    if (file && file !== null) {
      return (
        <img
          className="section-thumbnail d-block m-auto"
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
        />
      );
    }

    return (
      <div className="section-thumbnail d-flex align-items-center">
        <div className="d-block w-100 text-white text-center">
          <i className={`fa fa-${icon} d-block m-auto`}></i>
          {name}
        </div>
      </div>
    );
  };

  return <div>{renderContent()}</div>;
};
export default SectionThumbnail;
