import { Link } from "@reach/router";
import React from "react";
import { S3_ENDPOINT } from "../../utils";

const VideoCard = ({ video, agregarFavorito, eliminarFavorito }) => {
  const renderTitle = () => {
    if (String(video.name).length > 45) {
      return String(video.name).substring(0, 45) + "...";
    }
    return video.name;
  };

  const renderDescription = () => {
    if (String(video.description) > 100) {
      return video.description.substring(0, 100) + "...";
    }
    return video.description;
  };

  const renderPlaybacks = () => {
    if (video.playbacks !== null) {
      let playback = video.playbacks[0];
      if (playback) {
        return (
          <div className="d-inline">
            <i className="fa fa-eye me-2"></i>
            {playback.video_playbacks}
          </div>
        );
      }
    }
  };

  const renderInstructors = () => {
    if (video.video_instructors.length === 1) {
      const instructor = video.video_instructors[0].instructor;
      if (instructor !== null) {
        return instructor.nick_name !== null
          ? instructor.nick_name
          : instructor.name;
      }
    } else {
      return video.video_instructors.map(({ instructor }) => (
        <span className="d-block" key={instructor.instructor_id}>
          {instructor.nick_name !== null
            ? instructor.nick_name
            : instructor.name}
        </span>
      ));
    }
  };

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3 position-relative">
      <Link
        to={`/athome/mylatina/video/${video.video_id}`}
        className="text-dark no-decoration"
        style={{ zIndex: 0 }}
      >
        <div className="card shadow-sm px-0 py-0 card-video">
          <img
            className="video-thumbnail"
            src={`${S3_ENDPOINT}/${video.file.name}.${video.file.type}`}
            alt="video thumbnail"
          />
          <div className="card-body p-3 overflow-hidden">
            <h5 className="card-video-title">{renderTitle()}</h5>
            <p className="mb-0">{renderDescription()}</p>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-8">
                <p className="mb-1 bold">{renderInstructors()} </p>
              </div>
              <div className="col-4 text-right">
                {/*renderPlaybacks()*/}
                <button
                  className={`btn btn-link ${
                    video.favorites.length > 0 ? "text-primary" : "text-white"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (video.favorites.length > 0) {
                      eliminarFavorito(video.video_id);
                    } else {
                      agregarFavorito(video.video_id);
                    }
                  }}
                >
                  <i className="fa fa-heart"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div
        className="row mx-0"
        style={{
          position: "absolute",
          bottom: 50,
          zIndex: 1,
          right: "6px",
        }}
      ></div>
    </div>
  );
};

export default VideoCard;
