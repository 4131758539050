import api from "./api";

const route = "/class_categories";

export default {
  getClassCategories: () => api.get(route),
  getClassCategory: (class_category_id) =>
    api.get(`${route}/${class_category_id}`),
  getAvailableClassCategories: () => api.get(`${route}/available`),
};
