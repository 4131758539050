import React from "react";
import { S3_ENDPOINT } from "../../utils";
import moment from "moment";

const UserData = ({ user, signOut, handleEdit }) => {
  const {
    file,
    name,
    city,
    phone,
    email,
    last_name,
    birthdate,
    instagram,
    instagram_business,
  } = user;

  const getSrc = () => {
    let src = "Mi-Informacion.png";
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  return (
    <div className="container-fluid px-0">
      <div className="row mb-3 pb-3 border-bottom align-items-center mx-0">
        <div className="col-4 col-md-2 ps-0">
          <img
            src={getSrc()}
            className="w-100 br-10 profile-image"
            alt="Profile"
          />
        </div>
        <div className="col-8 col-md-10">
          <h4 className="mb-0">
            {name} {last_name}
          </h4>
        </div>
      </div>
      <div className="small mb-1">
        <i className="fa fa-envelope me-3"></i>
        {email}
      </div>
      <div className="small mb-1">
        <i className="fa fa-birthday-cake me-3"></i>
        {moment(birthdate).utc().format("DD MMM YYYY")}
      </div>
      <div className="small mb-1">
        <i className="fa fa-phone me-3"></i>
        {phone}
      </div>
      <div className="small mb-1">
        <i className="fa fa-map-pin me-3"></i>
        {city}
      </div>
      <div className="small mb-1">
        <i className="fab fa-instagram me-3"></i>
        {instagram}
      </div>
      <div className="small mb-1">
        <i className="fab fa-instagram me-3"></i>
        {instagram_business}
      </div>
      <div className="row">
        <div className="col-6">
          <button onClick={handleEdit} className="btn btn-primary">
            <i className="fa fa-edit me-2"></i>Editar Información
          </button>
        </div>
        <div className="col-6 text-end">
          <button onClick={signOut} className="btn text-danger border-danger">
            <i className="fa fa-sign-out-alt me-2"></i>Salir
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserData;
