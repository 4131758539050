import api from "./api";

const route = "/purchases";

export default {
  getPurchases: () => api.get(route),
  getSinglePurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
  cancelPurchase: (purchase_id, cancel_reason) =>
    api.put(`${route}/${purchase_id}/cancel`, { cancel_reason }),
};
