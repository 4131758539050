import React from "react";
import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";

const PanelNavbar = () => {
  return (
    <nav className="bg-dark hide-mobile side-menu">
      <div className="container-fluid px-0">
        <div className="px-3">
          <Link to="/" className="navbar-brand" href="#landing">
            <img
              src={`${S3_ENDPOINT}/latina-cert-logo.png`}
              className="mw-100 w-100"
            />
          </Link>
        </div>
        <div className="text-white mt-4">
          <ul className="side-menu-list">
            <li className="nav-item text-item">
              <Link to="/mylatina/courses/1" className="nav-link">
                How To Latina
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/mylatina/courses/4" className="nav-link">
                Instructora
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/mylatina/account" className="nav-link">
                Mi Cuenta
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/mylatina/accesos" className="nav-link">
                Mis Accesos
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/mylatina/metodos" className="nav-link">
                Mis Métodos
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/mylatina/pagos" className="nav-link">
                Mis Pagos
              </Link>
            </li>
            <li className="nav-item text-item">
              <a href="https://forms.gle/2x1vUonWDW16xhnA6" className="nav-link">
                Evento
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
