import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import { formatMonto, S3_ENDPOINT } from "../../utils";

const CheckoutCourse = ({ course_id }) => {
  const { descuento, course, getCourseCheckout } = useContext(CheckoutContext);

  useEffect(() => {
    getCourseCheckout(course_id);
  }, []);

  const renderResultadoDescuento = () => {
    if (descuento && descuento !== null) {
      let total = course.price;
      if (descuento.is_percent) {
        let porcentaje = parseFloat(1 - descuento.amount / 100);
        total = parseFloat(course.price) * porcentaje;
      } else {
        total = course.price - descuento.amount;
      }
      total = parseFloat(total).toFixed(2);
      return (
        <div className="container-fluid px-0">
          <h5>
            Total con Descuento: {"$"}
            {total} MXN {renderPeriod()}
          </h5>
        </div>
      );
    }
  };

  const renderDiscountPeriod = () => {
    if (descuento && descuento !== null) {
      if (descuento.first_invoice_only) {
        return `tu primer pago. Después $${formatMonto(
          course.price
        )} MXN ${renderPeriod()}`;
      }
    }
  };

  const renderPeriod = () => {
    if (course.subscription_period === "month") {
      return "por mes";
    }
  };

  const renderImage = () => {
    if (course.thumbnail !== null && course.file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${course.file.name}.${course.file.type}`}
          className="course-img mb-3"
        />
      );
    }
  };
  const renderCourse = () => {
    if (course && course !== null) {
      const price =
        course.sale_price !== null && course.sale_price !== ""
          ? course.sale_price
          : course.price;
      return (
        <div>
          {renderImage()}
          <h3>{course.name}</h3>
          <p>{course.description}</p>
          <p>{course.short_description}</p>
          <h5>
            Total: {"$"}
            {formatMonto(price)}
            {" MXN"} {renderPeriod()}
          </h5>
          {renderResultadoDescuento()}
        </div>
      );
    }
  };

  return (
    <div>
      <h3>Estás Comprando</h3>
      <div className="mb-3 card no-scale">{renderCourse()}</div>
    </div>
  );
};

export default CheckoutCourse;
