import React, { useContext } from "react";
import { S3_ENDPOINT } from "../../utils";
import { CoursesContext } from "../../context/CoursesContext";

const CourseInfo = () => {
  const { course } = useContext(CoursesContext);

  const renderImage = () => {
    if (course.course_id === 4) {
      return (
        <img
          src={`${S3_ENDPOINT}/Banner-Welcome-min.png`}
          className="course-info-img"
        />
      );
    }
    if (course.course_id === 1) {
      return (
        <img
          src={`${S3_ENDPOINT}/hello-latina.jpg`}
          className="course-info-img"
        />
      );
    }
    const { file } = course;
    if (file && file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
          className="course-info-img"
        />
      );
    }
  };

  return <div className="container-fluid px-0">{renderImage()}</div>;
};

export default CourseInfo;
