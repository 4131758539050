import React from "react";
import PurchaseCard from "./PurchaseCard";

const PurchasesTable = ({ handleCancel, purchases, courses }) => {
  const renderOrdenes = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return <p className="mt-3">No has hecho ninguna compra.</p>;
      }
      return purchases.map((purchase) => (
        <PurchaseCard
          handleCancel={handleCancel}
          key={purchase.purchase_id}
          purchase={purchase}
          courses={courses}
        />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light border bold py-2">
            <th>#ID</th>
            <th>Compra</th>
            <th>Total</th>
            <th>Estado</th>
            <th>Fecha y Hora</th>
            <th>Expira en</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>{renderOrdenes()}</tbody>
      </table>
    </div>
  );
};

export default PurchasesTable;
