import {
  CREATE_COURSE,
  COURSES_RECIBIDOS,
  SET_PROPERTY_COURSE,
  SINGLE_COURSE_RECIBIDO,
} from "../types";

const schema = {
  course_id: "nuevo",
  name: "",
  description: "",
  expiration_days: "",
};

export default (state, { type, payload }) => {
  switch (type) {
    case COURSES_RECIBIDOS:
      return { ...state, courses: payload };
    case SINGLE_COURSE_RECIBIDO:
      return { ...state, course: payload };
    case SET_PROPERTY_COURSE:
      const { key, value } = payload;
      const course = { ...state.course };
      course[key] = value;
      return { ...state, course };
    case CREATE_COURSE:
      return { ...state, course: schema };
    default:
      return { ...state };
  }
};
