import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import VideosReducer from "../reducers/VideosReducer";
import VideosService from "../services/VideosService";
import {
  VIDEOS_RECIBIDOS,
  SINGLE_VIDEO_RECIBIDO,
  PURCHASE_NEEDED,
  FAVORITOS_RECIBIDOS,
} from "../types/videos";
import { ModalContext } from "./ModalContext";

const initialState = {
  favoritos: null,
  videos: null,
  video: null,
};

export const VideosContext = createContext(initialState);

export const VideosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(VideosReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const getVideos = (filters) => {
    VideosService.getVideos(filters)
      .then((res) => {
        const { videos } = res.data;
        dispatch({ type: VIDEOS_RECIBIDOS, payload: videos });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            dispatch({ type: PURCHASE_NEEDED });
          }
        }
      });
  };

  const getFavoritos = (filters) => {
    VideosService.getFavoritos(filters).then((res) => {
      const { favorites } = res.data;
      dispatch({ type: FAVORITOS_RECIBIDOS, payload: favorites });
    });
  };

  const clearFavoritos = () => {
    dispatch({ type: FAVORITOS_RECIBIDOS, payload: null });
  };

  const getAllVideos = (query) => {
    VideosService.getAllVideos(query).then((res) => {
      const { videos } = res.data;
      dispatch({ type: VIDEOS_RECIBIDOS, payload: videos });
    });
  };

  const getSingleVideo = (video_id) => {
    VideosService.getSingleVideo(video_id)
      .then((res) => {
        const { video } = res.data;
        dispatch({ type: SINGLE_VIDEO_RECIBIDO, payload: video });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            alert("Lo sentimos, no hemos encontrado este video.");
            navigate("/athome/mylatina");
          } else if (error.response.status === 402) {
            alert("Lo sentimos, no tienes acceso a este video.");
            window.open("https://latinatribe.mx/athome#shop", "_self");
          }
        }
      });
  };

  const agregarFavorito = (video_id) => {
    VideosService.postFavorito(video_id).then(() => {
      getVideos();
      getSingleVideo(video_id);
      success("¡Favorito agregado!");
    });
  };

  const eliminarFavorito = (video_id) => {
    VideosService.deleteFavorito(video_id).then(() => {
      getVideos();
      getSingleVideo(video_id);
      success("¡Favorito eliminado!");
    });
  };

  const clearVideos = () => {
    dispatch({ type: VIDEOS_RECIBIDOS, payload: null });
  };
  return (
    <VideosContext.Provider
      value={{
        ...state,
        getVideos,
        clearVideos,
        getAllVideos,
        getFavoritos,
        clearFavoritos,
        getSingleVideo,
        agregarFavorito,
        eliminarFavorito,
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};
