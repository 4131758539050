import React from "react";
import { S3_ENDPOINT } from "../../utils";

const SingleMedia = ({ media }) => {
  const getClassName = () => {
    const { type } = media;
    if (["vimeo", "youtube", "adilo"].includes(type)) {
      return "container-fluid p-3";
    }
    return "col-12 col-md-6 col-lg-4";
  };
  const renderMedia = () => {
    const video_source = media.src;
    const { type } = media;
    if (type === "adilo") {
      return (
        <iframe
          src="https://adilo.bigcommand.com/watch/r_byWyxi"
          className="adilo-player"
          allowTransparency="true"
          allowFullScreen
        ></iframe>
      );
    }
    if (type === "vimeo") {
      return (
        <iframe
          className="video-iframe"
          src={`https://player.vimeo.com/video/${video_source}`}
          allowFullScreen
        ></iframe>
      );
    }
    if (type === "youtube") {
      const source =
        "https://www.youtube.com/embed/" + video_source.split("v=")[1];
      return (
        <iframe
          src={source}
          title="YouTube video player"
          className="mw-100 w-100 shadow"
          height="500px"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      );
    }
    if (type === "drive") {
      return (
        <iframe
          src={video_source}
          className="mw-100 w-100"
          height="auto"
          width="100%"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      );
    }
    if (type === "mp4") {
      return (
        <video className="lesson-media mw-100 w-100 mb-3" controls>
          <source
            src={`${S3_ENDPOINT}/${media.file.name}.${media.file.type}`}
            type="video/mp4"
          />
        </video>
      );
    }
    if (media.file && media.file !== null) {
      if (
        ["png", "jpg", "jpeg", "gif"].includes(
          String(media.file.type).toLowerCase()
        )
      ) {
        return (
          <img
            src={`${S3_ENDPOINT}/${media.file.name}.${media.file.type}`}
            className="mw-100 w-100 p-3 border lesson-media"
          />
        );
      }
      return (
        <a
          href={`${S3_ENDPOINT}/${media.file.name}.${media.file.type}`}
          target="_blank"
          className="lesson-media btn btn-outline-dark p-3"
        >
          <img
            src="https://latinacerts.s3.us-west-1.amazonaws.com/Icon-Marketing.png"
            className="lesson-media-thumbnail d-block m-auto"
          />
          <h4 className="h2">{media.name}</h4>
          <p className="small">
            {media.file.name}.{media.file.type}
          </p>
        </a>
      );
    }
  };

  return <div className={getClassName()}>{renderMedia()}</div>;
};

export default SingleMedia;
