import React, { createContext, useContext, useReducer } from "react";
import AuthService from "../services/AuthService";
import UsersService from "../services/UsersService";
import AdjuntosService from "../services/AdjuntosService";
import UserReducer from "../reducers/UserReducer";
import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  LOGIN,
  LOGOUT,
  SET_PROPERTY_USER,
  GUARDAR_USUARIO,
  EDITAR_USUARIO,
  REATTEMPT_LOGIN,
} from "../types";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import { TOKEN_KEY } from "../utils";

const initialState = {
  user: null,
  correo: null,
  password: null,
  telefono: null,
  cuenta: null,
  direccion: null,
  spinner: false,
  reattempt: false,
};

export const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  function signIn(email, password) {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signIn(email, password).catch((error) => {
      if (error.code === "auth/user-not-found") {
        alert(
          "Lo sentimos. No encontramos una cuenta con ese correo. ¡Regístrate!"
        );
      }
      if (error.code === "auth/wrong-password") {
        alert("La contraseña es incorrecta. Por favor, intenta de nuevo");
      } else {
        alert(error.toString());
      }
      dispatch({ type: HIDE_SPINNER });
    });
  }

  function userLoggedIn() {
    dispatch({ type: SHOW_SPINNER });
    AuthService.userLoggedIn(
      (user) => {
        if (user && user !== null) {
          let device_token = window.localStorage.getItem(TOKEN_KEY);
          AuthService.getToken().then((token) => {
            if (device_token !== null) {
              UsersService.setSessionToken(device_token);
            }
            UsersService.setToken(token);
            getUsuario();
          });
        }
      },
      (error) => {
        if (error) {
          alert(error);
          AuthService.signOut();
          navigate("/login");
        }
        dispatch({ type: HIDE_SPINNER });
      }
    );
  }

  function signOut() {
    AuthService.signOut()
      .then(() => {
        dispatch({ type: LOGOUT });
        navigate("/login");
      })
      .catch((error) => {
        alert(error);
      });
  }

  function signUp(
    nombre,
    apellidos,
    correo,
    password,
    telefono,
    signupReason,
    ciudad
  ) {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signUp(correo, password)
      .then((user) => {
        const uid = user.user.uid;
        UsersService.postUsuario(
          nombre,
          apellidos,
          correo,
          telefono,
          uid,
          signupReason,
          ciudad
        )
          .then(() => {
            success("¡Bienvenida!");
            AuthService.signIn(correo, password);
          })
          .catch((error) => {
            alert(error);
          });
      })
      .catch((error) => {
        if (error.code === 400) {
          return alert(
            "Bebé, ya existe una cuenta con este correo. Intenta iniciar sesión."
          );
        }
        alert(error.message);
      });
  }

  function getUsuario() {
    UsersService.getUsuario()
      .then((res) => {
        let { user } = res.data;
        const device_token = user.device_token;
        if (device_token !== null) {
          window.localStorage.setItem(TOKEN_KEY, device_token);
          UsersService.setSessionToken(device_token);
        }
        dispatch({ type: LOGIN, payload: user });
      })
      .catch((error) => {
        AuthService.signOut();
        dispatch({ type: LOGOUT });
        if (error.response) {
          if (error.response.status === 428) {
            if (window.location.href.includes("login")) {
              return dispatch({ type: REATTEMPT_LOGIN });
            } else {
              alert("Solo puedes una sesión activa al mismo tiempo.");
            }
            return navigate("/login");
          }
        }
        return alert(error);
      });
  }

  function editarUsuario() {
    dispatch({ type: EDITAR_USUARIO });
  }

  function cancelEdit() {
    dispatch({ type: GUARDAR_USUARIO });
  }

  function setPropiedadUser(key, value) {
    if (key === "file_id") {
      dispatch({
        type: SET_PROPERTY_USER,
        payload: { key: "newFile", value },
      });
      if (!value)
        dispatch({ type: SET_PROPERTY_USER, payload: { key, value } });
    } else {
      if (key === "telefono") {
        value = String(value).replace(/\D/g, "");
        value = String(value).substring(0, 10);
      }
      dispatch({ type: SET_PROPERTY_USER, payload: { key, value } });
    }
  }

  function recoverPassword(email) {
    AuthService.recoverPassword(email)
      .then(() => {
        success("Te hemos enviado un correo para reestablecer tu contraseña.");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          UsersService.recoverPassword(email).then(() => {
            success(
              "¡Te hemos enviado un correo para reestablecer tu contraseña!"
            );
          });
        } else {
          alert("Hubo un error al enviar el correo. Inténtalo más tarde.");
        }
      });
  }

  function updateUsuario(usuario) {
    const promises = [];
    if (usuario.newFile && usuario.newFile !== null) {
      if (usuario.newFile.name) {
        const promiseAdjunto = new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", usuario.newFile);
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { file_id } = res.data;
            usuario.file_id = file_id;
            resolve();
          });
        });
        promises.push(promiseAdjunto);
      }
    }
    Promise.all(promises).then(() => {
      const data = { ...usuario };
      delete data.file;
      delete data.uid;
      delete data.activo;
      UsersService.putUsuario(data)
        .then((res) => {
          dispatch({ type: GUARDAR_USUARIO });
          success("Perfil actualizado con éxito.");
        })
        .catch((error) => {
          alert(error);
        });
    });
  }

  const verifyEmail = () => {
    AuthService.verifyEmail().then(() => {
      success("Te enviamos un correo para confirmar tu dirección");
    });
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        getUsuario,
        cancelEdit,
        verifyEmail,
        userLoggedIn,
        updateUsuario,
        editarUsuario,
        recoverPassword,
        setPropiedadUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
