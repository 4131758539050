import { Link } from "@reach/router";
import React, { useEffect } from "react";
import Footer from "../components/global/Footer";
import Navbar from "../components/global/Navbar";
import { S3_ENDPOINT } from "../utils";
import "./Home.css";

const HowToLatina = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="home-page bg-dark">
      <Navbar />
      <div id="video" className="row video-row">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                alt="portada how to latina"
                className="d-block slideshow-img w-100 mw-100"
                src={`${S3_ENDPOINT}/howtolatina-cover.jpg`}
              />
              <div className="slideshow-text">
                <div className="row h-100 align-items-center">
                  <div className="col-12 col-md-6">
                    <div className="slideshow-content">
                      <img
                        id="howtolatina-logo"
                        alt="logo how to latina"
                        src={`${S3_ENDPOINT}/howtolatina-logo.png`}
                        className="mb-4"
                      />
                      <div className="text-lg">
                        <p>
                          Descubre lo que significa ser Latina dentro y fuera
                          del salón en este curso online de 3 módulos.
                          Aprenderás técnica básica del método Latina Tribe®
                          para mejorar tu desempeño en clases y gozarás al
                          máximo en una clase muestra.
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-8 col-xl-6">
                          <Link
                            to="/checkout/course/1"
                            className="btn btn-primary btn-lg mb-3 w-100 bold"
                          >
                            BUY NOW
                          </Link>
                        </div>
                        <div className="col-12 col-md-4 col-xl-6"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 h-600 hide-mobile">
                    <div className="row h-600 align-items-center">
                      <video
                        className="mw-100"
                        poster={`${S3_ENDPOINT}/howtolatina-cover.jpg`}
                        controls
                      >
                        <source
                          type="video/mp4"
                          src="https://latinacerts.s3.us-west-1.amazonaws.com/trailer-how-to-latina.mp4"
                        />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <section className="py-5 px-2">
        <div className="container-fluid">
          <h2 className="text-center text-primary mb-5">WHAT TO EXPECT</h2>
          <div className="row">
            <div className="col-12 col-md-6 mt-5">
              <div className="card m-auto">
                <img
                  alt="que incluye"
                  src={`${S3_ENDPOINT}/howtolatina-1.jpg`}
                  className="mw-100 w-100 m-auto my-0 d-block"
                />
                <div className="content">
                  <h3 className="text-primary">¿QUÉ INCLUYE?</h3>
                  <p>Acceso por 7 días al contenido del curso:</p>
                  <p>
                    - 3 módulos de video (50 minutos c/u) <br />- Manual PDF
                    “How To Latina” <br />- Clase Demo Latina Power Moves
                  </p>
                  <p className="small">
                    *How To Latina NO es un entrenamiento para instructoras y su
                    realización no tiene validez como licencia para impartir
                    clases.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-5">
              <div className="card m-auto">
                <img
                  alt="que vas a aprender"
                  src={`${S3_ENDPOINT}/howtolatina-2.jpg`}
                  className="mw-100 w-100 m-auto my-0 d-block"
                />
                <div className="content">
                  <h3 className="text-primary">¿QUÉ VAS A APRENDER?</h3>
                  <p className="text-center">
                    Descubre lo que significa ser Latina dentro y fuera del
                    salón. Aprenderás técnica básica del método Latina Tribe®
                    upper & lower body con ejercicios prácticos. Practicarás la
                    coreografía de “Booty” paso a paso y gozarás al máximo en
                    una clase demo. Conocerás lo que significa ser Latina a
                    través del testimonio de nuestras co-fundadoras.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default HowToLatina;
