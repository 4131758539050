import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";
import StatusBadge from "../global/StatusBadge";

const InvoiceCard = ({ invoice, courses }) => {
  const renderCourse = () => {
    if (invoice && invoice !== null) {
      let { amount } = invoice;
      amount = String(amount);
      if (amount === "499" || amount === "0") {
        return "Instructor Membership";
      }
      if (amount === "3000" || amount === "6450") {
        return "Latina Certification";
      }
    }
  };
  const renderExpiration = () => {
    if (invoice.expiration_date !== null) {
      return moment(invoice.expiration_date).format("DD MMM YYYY HH:mm");
    }
    if (invoice.subscription_id !== null && invoice.status === "cancelled") {
      let free_trial_cancel = moment(invoice.createdAt);
      if (invoice.free_trial_length !== null) {
        free_trial_cancel.add(invoice.free_trial_length, "days");
      }
      let cancel_expiration = moment(invoice.updatedAt).add(
        invoice.subscription_interval,
        invoice.subscription_period
      );
      let expiration_date = cancel_expiration;
      if (free_trial_cancel.isAfter(cancel_expiration)) {
        expiration_date = free_trial_cancel;
      }
      return expiration_date.format("DD MMM YYYY");
    }
  };
  return (
    <tr className="border">
      <td>{invoice.invoice_id}</td>
      <td>{renderCourse()}</td>
      <td>
        {"$"}
        {formatMonto(invoice.amount)}
        {" MXN"}
      </td>
      <td>
        <StatusBadge status={invoice.status} order_id={invoice.order_id} />
      </td>
      <td className="datetime">
        {moment(invoice.createdAt).format("DD MMM YYYY HH:mm")}
      </td>
      <td className="datetime">{renderExpiration()}</td>
    </tr>
  );
};

export default InvoiceCard;
