import React from "react";
import parse from "html-react-parser";

const LessonContent = ({ lesson }) => {
  const renderContent = () => {
    if (lesson && lesson !== null) {
      if (String(lesson.content).startsWith("<")) {
        return parse(lesson.content);
      }
      return <p>{lesson.content}</p>;
    }
  };
  return (
    <div className="card no-scale shadow-sm border mb-3">{renderContent}</div>
  );
};

export default LessonContent;
