import api from "./api";

const route = "/payment_sources";

export default {
  getMetodos: () => api.get(route),
  deleteMetodoPago: (payment_source_id) =>
    api.delete(`${route}/${payment_source_id}`),
  postMetodoPago: (token_id, card_type, last_digits, saveCard) =>
    api.post(route, { token_id, card_type, last_digits, saveCard }),
  postPaymentMethod: (payment_method) =>
    api.post(`${route}/stripe`, { payment_method }),
};
