import React from "react";
import SingleResource from "../resources/SingleResource";

const LessonResources = ({ lesson }) => {
  const renderResources = () => {
    if (lesson && lesson !== null) {
      if (Array.isArray(lesson.resources)) {
        return lesson.resources.map((resource) => (
          <SingleResource
            key={resource.lesson_resource_id}
            resource={resource}
          />
        ));
      }
    }
  };

  return (
    <div className="card no-scale shadow-sm border mb-3">
      <h4 className="mt-4 border-bottom mb-3 pb-2">Descargables</h4>
      {renderResources()}
    </div>
  );
};

export default LessonResources;
