import React from "react";

const FilterForm = ({ filters, values, setFilter }) => {
  const renderOptions = (key) => {
    let options = values[key];
    if (Array.isArray(options)) {
      return [
        <option key="all" value={null}>
          Todo
        </option>,
        ...options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        )),
      ];
    }
  };

  const { author, query, level, instructor_id, showFavorites } = filters;

  return (
    <div className="row my-2">
      <div className="col-12 col-md-4 mb-2">
        <label>Palabra Clave</label>
        <input
          type="text"
          className="form-control"
          value={query}
          placeholder="Buscar por nombre de clase..."
          onChange={(e) => setFilter("query", e.target.value)}
        />
      </div>
      <div className="col-12 col-md-2 mb-2">
        <label>Artista</label>
        <select
          value={author}
          className="form-control"
          onChange={(e) => setFilter("author", e.target.value)}
        >
          {renderOptions("author")}
        </select>
      </div>
      <div className="col-12 col-md-2 mb-2">
        <label>Nivel</label>
        <select
          value={level}
          className="form-control"
          onChange={(e) => setFilter("level", e.target.value)}
        >
          {renderOptions("level")}
        </select>
      </div>
      <div className="col-12 col-md-2 mb-2">
        <label>Coach</label>
        <select
          value={instructor_id}
          className="form-control"
          onChange={(e) => setFilter("instructor_id", e.target.value)}
        >
          {renderOptions("instructor_id")}
        </select>
      </div>
      <div className="col-12 col-md-2 mb-2">
        <button
          className={`btn btn-light shadow-sm mt-4 border ${
            showFavorites ? "text-danger" : "text-dark"
          }`}
          onClick={() => setFilter("showFavorites", !showFavorites)}
        >
          <i className="fa fa-heart"></i>{" "}
        </button>
      </div>
    </div>
  );
};

export default FilterForm;
