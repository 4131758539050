import React, { useState, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const CancelPurchaseForm = ({ purchase, cancelPurchase }) => {
  const [cancelReason, setCancelReason] = useState("");

  const { alert } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (String(cancelReason).length < 10) {
      return alert("Debes ingresar una razón válida.");
    }
    cancelPurchase(purchase.purchase_id, cancelReason);
  };
  return (
    <form onSubmit={handleSubmit}>
      <p>
        ¿Estás segura que deseas cancelar la compra #{purchase.purchase_id}.
        Esta ACCIÓN no puede deshacerse, perderás acceso al contenido al final
        de tu periodo de facturación.
      </p>
      <label className="bold d-block">
        Motivo de cancelación (Obligatorio)
      </label>
      <input
        type="text"
        className="form-control mb-3"
        value={cancelReason}
        onChange={(e) => setCancelReason(e.target.value)}
      />
      <button type="submit" className="btn btn-danger">
        Cancelar
      </button>
    </form>
  );
};

export default CancelPurchaseForm;
