/**
 * Videos
 */
export const VIDEOS_RECIBIDOS = "VIDEOS_RECIBIDOS";
export const SINGLE_VIDEO_RECIBIDO = "SINGLE_VIDEO_RECIBIDO";
export const SET_PROPERTY_VIDEO = "SET_PROPERTY_VIDEO";
export const CLEAR_VIDEOS = "CLEAR_VIDEOS";
export const APPEND_VIDEOS = "APPEND_VIDEOS";
export const CREATE_VIDEO = "CREATE_VIDEO";
export const PURCHASE_NEEDED = "PURCHASE_NEEDED";
export const FAVORITOS_RECIBIDOS = "FAVORITOS_RECIBIDOS";
/**
 * Favoritos
 */
export const AGREGAR_FAVORITO = "AGREGAR_FAVORITO";
export const ELIMINAR_FAVORITO = "ELIMINAR_FAVORITO";
/**
 * Video Type
 */
export const VIDEO_TYPE_RECIBIDOS = "VIDEO_TYPE_RECIBIDOS";
