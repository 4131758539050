import { navigate } from "@reach/router";
import React, { useContext, useEffect } from "react";
import SignUpForm from "../components/auth/SignUpForm";
import { UserContext } from "../context/UserContext";

const SignUp = () => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user !== null && !window.location.href.includes("mylatina")) {
      navigate("/mylatina");
    }
  }, [user]);

  return (
    <div className="container-fluid bg-black">
      <div
        className="row position-absolute w-100 bg-vertical vh-100"
        style={{ zIndex: 0 }}
      ></div>
      <div
        className="row position-absolute w-100 bg-dark vh-100"
        style={{ opacity: 0.6, zIndex: 1 }}
      ></div>
      <div className="row align-items-center vh-100">
        <div className="container-fluid">
          <SignUpForm theme="dark" />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
