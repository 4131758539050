import { Link } from "@reach/router";
import React from "react";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top py-3">
      <div className="container-fluid navbar-container">
        <Link to="/">
          <img src="/img/Latina_Icono2.png" className="logo-navbar" />
        </Link>
        <Link
          to="/login"
          className="btn btn-login show-mobile position-relative"
        >
          <i className="fa fa-heart text-primary"></i>
          <span className="d-block bold archivo position-relative">Log In</span>
        </Link>
        <div className="collapse navbar-collapse mw-100" id="navbarNav">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col col-md-8 col-xl-10"></div>
              <div className="col col-md-4 col-xl-2 text-right pe-4 hide-mobile">
                <Link to="/login" className="btn btn-login position-relative">
                  <i className="fa fa-heart text-primary"></i>
                  <span className="d-block bold archivo position-relative">
                    Log In
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
