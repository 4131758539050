import React from "react";

const StatusBadge = ({ status, order_id }) => {
  const getBadgeClassName = () => {
    let className = "";
    if (["revoked", "cancelled", "failed"].includes(status)) {
      className = "bg-danger";
    } else if (["success", "active", "completed"].includes(status)) {
      className = "bg-success";
    } else if (["pending", "on-hold"].includes(status) && order_id !== null) {
      className = "bg-warning text-dark";
    } else if (order_id === null) {
      className = "bg-danger";
    } else {
      className = "bg-secondary";
    }
    return className;
  };

  const getBadgeText = () => {
    if (order_id === null && status === "pending") {
      return "Rechazada";
    }
    if (["success", "completed"].includes(status)) {
      return "Exitosa";
    }
    if (status === "active") {
      return "Activa";
    }
    return status;
  };

  return (
    <div className="d-inline-block">
      <span
        className={`text-capitalize badge badge-pill ${getBadgeClassName()}`}
      >
        {getBadgeText()}
      </span>
    </div>
  );
};
export default StatusBadge;
