import React, { useState, useContext } from "react";
import { Link } from "@reach/router";
import { UserContext } from "../../context/UserContext";
import { validateEmail, validatePhoneNumber } from "../../utils";

const SignUpForm = ({ theme, signInAction }) => {
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [telefono, setTelefono] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [signupReason, setSignUpReason] = useState("Recomendación de amiga");
  const [customReason, setCustomReason] = useState("");
  const { spinner, signUp } = useContext(UserContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nombre === "") {
      return alert("Debes ingresar tu nombre.");
    }
    if (apellidos === "") {
      return alert("Debes ingresar tus apellidos.");
    }
    if (!validateEmail(email)) {
      return alert("El correo electrónico no es válido.");
    }
    if (String(password).length < 6) {
      return alert("La contraseña debe tener al menos 6 caracteres.");
    }
    if (!validatePhoneNumber(telefono)) {
      return alert("Debes ingresar un número de teléfono válido.");
    }
    if (ciudad === "") {
      return alert("Debes ingresar tu ciudad");
    }
    signUp(
      nombre,
      apellidos,
      email,
      password,
      telefono,
      signupReason === "otro" ? customReason : signupReason,
      ciudad
    );
  };

  const renderOtro = () => {
    if (signupReason === "otro") {
      return (
        <div>
          <label>Cuéntanos</label>
          <input
            type="text"
            className="form-control mb-3"
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
          />
        </div>
      );
    }
  };

  return (
    <div id="login-card" className={theme === "dark" ? "text-white" : ""}>
      <h2 className="text-center mb-3">Join the Tribe</h2>
      <div
        className={`card no-scale text-left shadow p-4 ${
          theme === "dark" ? "bg-gray text-white" : ""
        }`}
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-6">
              {" "}
              <label>Nombre</label>
              <input
                type="text"
                className="form-control mb-3"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>Apellidos</label>
              <input
                type="text"
                className="form-control mb-3"
                value={apellidos}
                onChange={(e) => setApellidos(e.target.value)}
              />
            </div>
          </div>

          <label>Correo Electrónico</label>
          <input
            type="email"
            className="form-control mb-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Contraseña</label>
          <input
            type="password"
            className="form-control mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>Confirmar Contraseña</label>
          <input
            type="password"
            className="form-control mb-3"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          <label>Teléfono (WhatsApp)</label>
          <input
            type="tel"
            className="form-control mb-3"
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
          />
          <label>Ciudad donde Vives</label>
          <input
            type="text"
            className="form-control mb-3"
            value={ciudad}
            onChange={(e) => setCiudad(e.target.value)}
          />
          <label>¿Cómo te enteraste de nosotras?</label>
          <select
            className="form-control mb-3"
            value={signupReason}
            onChange={(e) => setSignUpReason(e.target.value)}
          >
            <option>Recomendación de amiga</option>
            <option>Anuncio en Instagram</option>
            <option>Anuncio en Facebook</option>
            <option>Soy fan de una o más Latinas en Ig</option>
            <option>Vi el local</option>
            <option value="otro">Otro</option>
          </select>
          {renderOtro()}
          <button type="submit" className="btn btn-primary" value="Entrar">
            {spinner ? <div className="spinner-border"></div> : "Regístrate"}
          </button>
        </form>
        <div className="container-fluid px-0 mt-4">
          ¿Ya tienes cuenta?{" "}
          {signInAction ? (
            <button onClick={signInAction} className="btn btn-link">
              Inicia Sesión
            </button>
          ) : (
            <Link to="/login">Inicia Sesión</Link>
          )}
        </div>
      </div>
      <p className="mt-4 mw-500 d-block m-auto">
        <span className={theme === "dark" ? "text-white" : "text-dark"}>
          ¿Necesitas recuperar tu cuenta?
        </span>{" "}
        <Link to="/recuperar">Haz click aquí</Link>
      </p>
    </div>
  );
};

export default SignUpForm;
