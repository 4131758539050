import { SET_FILTER, SET_FILTER_VALUES } from "../types";

const FiltersReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_FILTER:
      const { key, value } = payload;
      return { ...state, [key]: value };
    case SET_FILTER_VALUES: {
      const { key } = payload;
      const values = { ...state.values };
      values[key] = payload.values;
      return { ...state, values };
    }
    default:
      return { ...state };
  }
};
export default FiltersReducer;
