import React, { useContext, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { UserContext } from "../../context/UserContext";
import { cardStyle } from "../../utils";

const StripeCardForm = ({ submitted, modifier }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (submitted) {
      handleSubmitCard();
    }
  }, [submitted]);

  const handleSubmitCard = async () => {
    const card = elements.getElement(CardElement);
    const payment_method = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        email: user.email,
      },
    });
    if (typeof modifier === "function") {
      return modifier(payment_method);
    }
  };

  return (
    <div className="container-fluid px-0">
      <CardElement
        id="card-element"
        options={cardStyle}
        className="form-control pt-2 my-2"
      />
    </div>
  );
};

export default StripeCardForm;
