import { navigate } from "@reach/router";
import React, { createContext, useReducer } from "react";
import CoursesReducer from "../reducers/CoursesReducer";
import CoursesService from "../services/CoursesService";
import {
  CREATE_COURSE,
  COURSES_RECIBIDOS,
  HIDE_SPINNER,
  SET_PROPERTY_COURSE,
  SHOW_SPINNER,
  SINGLE_COURSE_RECIBIDO,
} from "../types";

const initialState = {
  spinner: false,
  courses: null,
  course: null,
};

export const CoursesContext = createContext(initialState);

export const CoursesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CoursesReducer, initialState);

  const getCourses = () => {
    CoursesService.getCourses().then((res) => {
      const { courses } = res.data;
      dispatch({ type: COURSES_RECIBIDOS, payload: courses });
    });
  };

  const getSingleCourse = (course_id) => {
    CoursesService.getSingleCourse(course_id)
      .then((res) => {
        const { course } = res.data;
        dispatch({ type: SINGLE_COURSE_RECIBIDO, payload: course });
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          const { status } = response;
          if (status === 412) {
            navigate(`/mylatina/sorry/${course_id}`);
          }
        }
      });
  };

  const clearCourse = () => {
    dispatch({ type: SINGLE_COURSE_RECIBIDO, payload: null });
  };

  const createCourse = () => {
    dispatch({ type: CREATE_COURSE });
  };

  const setPropiedadCourse = (key, value) => {
    dispatch({ type: SET_PROPERTY_COURSE, payload: { key, value } });
  };

  const postCourse = (course) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: HIDE_SPINNER });
  };

  return (
    <CoursesContext.Provider
      value={{
        ...state,
        postCourse,
        getCourses,
        clearCourse,
        createCourse,
        getSingleCourse,
        setPropiedadCourse,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};
