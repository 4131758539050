import React from "react";
import { formatMonto, S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";

const HomeCourseCard = ({ course }) => {
  const { src, name, slug, badge, price, price_info, description } = course;
  return (
    <Link to={slug} className="no-decoration text-white card m-auto">
      <div className="badge bg-white text-dark px-3 py-2">{badge}</div>
      <img
        src={`${S3_ENDPOINT}/${src}`}
        className="mw-100 w-100 m-auto my-0 d-block"
      />
      <div className="p-3 py-4">
        <h3 className="mb-0 text-primary">{name}</h3>
        <h4 className="text-primary">16, 17 y 18 de agosto</h4>
        <p className="description my-4">{description}</p>
        <h4>Desde ${formatMonto(price)} MXN*</h4>
        <p>{price_info}</p>
      </div>
    </Link>
  );
};

export default HomeCourseCard;
