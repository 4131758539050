import { getArgs } from "../utils";
import api from "./api";

const route = "/videos";

const VideosService = {
  getVideos: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getAllVideos: (query) =>
    api.get(
      `${route}/admin/all${
        query !== "" && query && query !== null ? `&query=${query}` : ""
      }`
    ),
  getFavoritos: (query) => api.get(`/favorites?${getArgs(query).slice(1)}`),
  getSingleVideo: (video_id) => api.get(`${route}/${video_id}`),
  postFavorito: (video_id) => api.post("/favorites", { video_id }),
  deleteFavorito: (video_id) => api.delete(`/favorites/${video_id}`),
};

export default VideosService;
