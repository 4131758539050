import {
  CLASS_CATEGORIES_RECIBIDAS,
  CLASS_CATEGORY_RECIBIDA,
} from "../types/class_categories";

export default (state, { type, payload }) => {
  switch (type) {
    case CLASS_CATEGORIES_RECIBIDAS: {
      return { ...state, class_categories: payload };
    }
    case CLASS_CATEGORY_RECIBIDA:
      return { ...state, class_category: payload };

    default:
      return { ...state };
  }
};
