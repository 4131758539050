import { Link } from "@reach/router";
import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";

const Recuperar = () => {
  const [email, setEmail] = useState("");
  const { spinner, recoverPassword } = useContext(UserContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    recoverPassword(email);
  };

  return (
    <div className="container-fluid">
      <div
        className="row position-absolute w-100 bg-vertical vh-100"
        style={{ zIndex: 0 }}
      ></div>
      <div
        className="row position-absolute w-100 bg-dark vh-100"
        style={{ opacity: 0.6, zIndex: 1 }}
      ></div>
      <div className="row text-white align-items-center vh-100">
        <div className="container-fluid">
          <h1 className="text-center h3 my-4">Reestablece tu contraseña</h1>
          <div
            id="login-card"
            className="card bg-gray text-white no-scale text-left shadow p-4"
          >
            <form onSubmit={handleSubmit}>
              <label>Correo Electrónico</label>
              <input
                type="email"
                className="form-control mb-3"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" className="btn btn-primary" value="Entrar">
                {spinner ? <div className="spinner-border"></div> : "Recuperar"}
              </button>
            </form>
            <div className="container-fluid px-0 mt-4">
              ¿Recordaste tu contraseña?{" "}
              <Link to={"/login"}>Inicia Sesión</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recuperar;
