/**
 * Lessons Types
 */
export const SET_LESSON = "SET_LESSON";
export const CREATE_LESSON = "CREATE_LESSON";
export const APPEND_LESSONS = "APPEND_LESSONS";
export const LESSONS_RECIBIDAS = "LESSONS_RECIBIDAS";
export const AGREGAR_MULTIMEDIA = "AGREGAR_MULTIMEDIA";
export const ELIMINAR_MULTMEDIA = "ELIMINAR_MULTMEDIA";
export const SET_PROPERTY_LESSON = "SET_PROPERTY_LESSON";
export const AGREGAR_DESCARGABLE = "AGREGAR_DESCARGABLE";
export const ELIMINAR_DESCARGABLE = "ELIMINAR_DESCARGABLE";
export const SET_ARCHIVO_MULTIMEDIA = "SET_ARCHIVO_MULTIMEDIA";
export const SET_PROPERTY_MULTIMEDIA = "SET_PROPERTY_MULTIMEDIA";
export const SET_ARCHIVO_DESCARGABLE = "SET_ARCHIVO_DESCARGABLE";
export const SET_PROPERTY_DESCARGABLE = "SET_PROPERTY_DESCARGABLE";
