import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { CoursesContext } from "../../context/CoursesContext";
import { SectionsContext } from "../../context/SectionsContext";
import SectionCard from "./SectionCard";

const userSection = {
  icon: "User",
  course_id: 4,
  name: "Inicio",
  link: "/mylatina/account",
};

const Sections = ({ course_id }) => {
  const { sections, selectSection, getSectionsCourse } =
    useContext(SectionsContext);
  const { curso, getCurso } = useContext(CoursesContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getSectionsCourse(course_id);
    //return clearSections;
  }, []);

  useEffect(() => {
    if (curso === null) {
      getCurso(course_id);
    }
  }, [course_id]);

  const renderContent = () => {
    if (user && user !== null && sections !== null) {
      if (sections.length === 0) {
        return <p className="px-2">Aún no hay módulos en este curso.</p>;
      }
      const sectionsRender = sections.sort((a, b) =>
        a.orden > b.orden ? 1 : -1
      );
      return sectionsRender.map((section) => (
        <SectionCard
          key={section.section_id}
          section={section}
          selectSection={selectSection}
        />
      ));
    }
    return <div className="spinner-border m-3"></div>;
  };

  return (
    <div className="container px-0">
      {renderContent()}
      <SectionCard section={userSection} selectSection={selectSection} />
    </div>
  );
};

export default Sections;
