import { INVOICES_RECEIVED } from "../types/invoices";

const InvoicesReducer = (state, { type, payload }) => {
  switch (type) {
    case INVOICES_RECEIVED:
      return { ...state, invoices: payload };
    default:
      return { ...state };
  }
};
export default InvoicesReducer;
