import React from "react";
import mastercard from "../../assets/images/payment-method-mc.png";
import visa from "../../assets/images/payment-method-visa.png";
import amex from "../../assets/images/payment-method-amex.png";
import CheckoutInstallmentCard from "../checkout/CheckoutInstallmentCard";

const PaymentSource = ({
  paymentMethod,
  payment_source,
  setPaymentMethod,
  installmentsOptions,
  selectedInstallments,
  setSelectedInstallments,
}) => {
  const renderInstallmentOptions = () => {
    if (
      Array.isArray(installmentsOptions) &&
      paymentMethod === payment_source_id
    ) {
      return (
        <div className="mt-4">
          <p className="bold">Plan de Pagos</p>
          {installmentsOptions.map((option, index) => (
            <CheckoutInstallmentCard
              key={index}
              {...option}
              selected={selectedInstallments}
              setSelected={setSelectedInstallments}
            />
          ))}
        </div>
      );
    }
  };

  const { payment_source_id, card_type, last_digits } = payment_source;

  return (
    <div
      onClick={() => setPaymentMethod(payment_source_id)}
      className={`card no-scale mb-3 ${
        paymentMethod === payment_source_id ? "border-primary" : ""
      }`}
    >
      <div className="row">
        <div className="col-1">
          <input type="radio" checked={paymentMethod === payment_source_id} />
        </div>
        <div className="col-11">
          <div className="row align-items-center">
            <div className="col col-md-4">
              <img
                src={
                  card_type === "mastercard"
                    ? mastercard
                    : card_type === "visa"
                    ? visa
                    : amex
                }
                className="card-type"
                alt="card type"
              />
            </div>
            <div className="col col-md-4 capitalize">{card_type}</div>
            <div className="col col-md-4">
              {"**** "}
              {last_digits}
            </div>
          </div>
        </div>
      </div>
      {renderInstallmentOptions()}
    </div>
  );
};

export default PaymentSource;
