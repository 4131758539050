import React, { useContext, useEffect } from "react";
import { CoursesContext } from "../../context/CoursesContext";
import { FiltersContext } from "../../context/FiltersContext";
import { LessonsContext } from "../../context/LessonsContext";
import LessonCard from "./LessonCard";
import LessonRow from "./LessonRow";

const SectionLessons = ({ course_id, section_id }) => {
  const { setFilterValues } = useContext(FiltersContext);
  const { lessons, clearLessons, getSectionLessons } =
    useContext(LessonsContext);
  const { course, getSingleCourse } = useContext(CoursesContext);

  const { query, author, level, showFavorites, instructor_id } =
    useContext(FiltersContext);

  useEffect(() => {
    getSectionLessons(section_id);
    return clearLessons;
  }, [section_id]);

  useEffect(() => {
    if (Array.isArray(lessons) && parseInt(section_id) === 4) {
      let instructorSet = new Set();
      let authorSet = new Set();
      let levelSet = new Set();
      lessons.forEach((lesson) => {
        instructorSet.add(lesson.instructor_id);
        authorSet.add(lesson.author);
        levelSet.add(lesson.level);
      });
      let instructorArray = Array.from(instructorSet);
      let instructors = [];
      instructorArray.forEach((instructor_id) => {
        let lesson = lessons.find(
          (lesson) => lesson.instructor_id === instructor_id
        );
        let instructor = lesson.instructor;
        instructors.push(instructor);
      });
      instructors = instructors
        .filter((instructor) => instructor !== null)
        .map((instructor) => ({
          name: instructor.name,
          value: instructor.instructor_id,
        }));
      let authors = Array.from(authorSet).map((author) => ({
        name: author,
        value: author,
      }));
      let levels = Array.from(levelSet).map((level) => ({
        name: level,
        value: level,
      }));
      setFilterValues("instructor_id", instructors);
      setFilterValues("author", authors);
      setFilterValues("level", levels);
    }
  }, [lessons]);

  useEffect(() => {
    if (course === null) {
      getSingleCourse(course_id);
    }
  }, [course_id]);

  const renderLecciones = () => {
    if (Array.isArray(lessons)) {
      let lessonsRender = lessons.sort((a, b) => (a.orden > b.orden ? 1 : -1));
      lessonsRender = lessonsRender.filter(
        (lesson) => parseInt(lesson.section_id) === parseInt(section_id)
      );
      if (lessonsRender.length === 0) {
        return <p>Aún no hay contenido disponible.</p>;
      }
      if (showFavorites) {
        lessonsRender = lessonsRender.filter(
          (lesson) => lesson.favorites.length > 0
        );
      }
      if (query !== null && query !== "") {
        let currentQuery = String(query).toLowerCase();
        lessonsRender = lessonsRender.filter((lesson) => {
          let name = String(lesson.name).toLowerCase();
          let description = String(lesson.description).toLowerCase();
          return (
            name.startsWith(currentQuery) ||
            name.includes(currentQuery) ||
            description.startsWith(currentQuery) ||
            description.includes(currentQuery)
          );
        });
      }
      if (author !== null) {
        lessonsRender = lessonsRender.filter(
          (lesson) => lesson.author === author
        );
      }
      if (level !== null) {
        lessonsRender = lessonsRender.filter(
          (lesson) => lesson.level === level
        );
      }
      if (instructor_id !== null) {
        lessonsRender = lessonsRender.filter(
          (lesson) => parseInt(lesson.instructor_id) === parseInt(instructor_id)
        );
      }
      if (parseInt(course_id) === 4 && parseInt(section_id) === 4) {
        return lessonsRender.map((lesson) => (
          <LessonCard key={lesson.lesson_id} currentLesson={lesson} />
        ));
      }
      return lessonsRender.map((lesson) => (
        <LessonRow key={lesson.lesson_id} currentLesson={lesson} />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="section-lessons row bg-light pt-3 pb-2">
      {renderLecciones()}
    </div>
  );
};

export default SectionLessons;
