import React, { useState, useContext, useEffect } from "react";
import { Link } from "@reach/router";
import { UserContext } from "../../context/UserContext";

const LoginForm = ({ theme, signUpAction }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { spinner, signIn, reattempt } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    return () => {
      setEmail("");
      setPassword("");
    };
  }, []);

  useEffect(() => {
    if (reattempt && email !== "" && password !== "") {
      signIn(email, password);
    }
  }, [reattempt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div id="login-card" className={theme === "dark" ? "text-white" : ""}>
      <h2 className="text-center mb-3">WELCOME BACK!</h2>
      <div
        className={`card no-scale text-left shadow p-4 ${
          theme === "dark" ? "bg-gray text-white" : ""
        }`}
      >
        <form onSubmit={handleSubmit}>
          <label>Correo Electrónico</label>
          <input
            type="email"
            className="form-control mb-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>
            Contraseña{" "}
            <button
              className="btn btn-link text-muted text-left text-small py-0 mb-1"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                togglePassword();
              }}
            >
              <span className="text-montserrat text-small text-auto">
                {showPassword ? "Ocultar" : "Mostrar"}
              </span>
            </button>
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="btn btn-primary" value="Entrar">
            {spinner ? <div className="spinner-border"></div> : "Entrar"}
          </button>
        </form>
        <div className="container-fluid px-0 mt-4">
          ¿Aun no tienes cuenta?{" "}
          {signUpAction ? (
            <button onClick={signUpAction} className="btn btn-link">
              Crea tu cuenta
            </button>
          ) : (
            <Link to="/signup">Crea tu cuenta</Link>
          )}
        </div>
        <div className="container-fluid px-0 mt-4">
          <Link to="/recuperar">¿Olvidaste tu contraseña?</Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
