import React from "react";
import mastercard from "../../assets/images/payment-method-mc.png";
import visa from "../../assets/images/payment-method-visa.png";
import amex from "../../assets/images/payment-method-amex.png";

const MetodoCard = ({ metodo, deleteMetodo }) => {
  const { payment_source_id, card_type, last_digits } = metodo;
  return (
    <tr>
      <td className="small">#{payment_source_id}</td>
      <td className="text-capitalize">
        <img
          src={
            card_type === "mastercard"
              ? mastercard
              : card_type === "visa"
              ? visa
              : amex
          }
          className="card-type"
          alt="card type"
        />
        <span className="mx-2 hide-mobile">{card_type}</span>
      </td>
      <td>
        {"**** "}
        {last_digits}
      </td>
      <td>
        <button
          className="btn btn-sm text-small btn-outline-danger"
          onClick={() => deleteMetodo(metodo)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};
export default MetodoCard;
