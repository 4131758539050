import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import CheckoutReducer from "../reducers/CheckoutReducer";
import CheckoutService from "../services/CheckoutService";
import {
  HIDE_SPINNER_DESCUENTO,
  SHOW_SPINNER_DESCUENTO,
  SINGLE_COURSE_RECIBIDO,
  SET_PAYMENT_SOURCE,
  SET_DISCOUNT_CODE,
  SET_DESCUENTO,
  SHOW_SPINNER,
  HIDE_SPINNER,
  SET_PAQUETE,
} from "../types";
import { ModalContext } from "./ModalContext";
import DescuentosService from "../services/DescuentosService";

const initialState = {
  course: null,
  discountCode: null,
  payment_source_id: "card",
  spinnerDescuento: false,
};

export const CheckoutContext = createContext(initialState);

export const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CheckoutReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const setPaquete = (paquete) => {
    dispatch({ type: SET_PAQUETE, payload: paquete });
  };

  const setDiscountCode = (code) => {
    dispatch({ type: SET_DISCOUNT_CODE, payload: code });
  };

  const setDescuento = (descuento) => {
    dispatch({ type: SET_DESCUENTO, payload: descuento });
  };

  const getCourseCheckout = (course_id) => {
    CheckoutService.getCourseCheckout(course_id).then((res) => {
      const { course } = res.data;
      dispatch({ type: SINGLE_COURSE_RECIBIDO, payload: course });
    });
  };

  const setPaymentSource = (payment_source_id) => {
    dispatch({ type: SET_PAYMENT_SOURCE, payload: payment_source_id });
  };

  const validarDescuento = (code) => {
    dispatch({ type: SHOW_SPINNER_DESCUENTO });
    DescuentosService.validarDescuento(code)
      .then((res) => {
        const { descuento, error } = res.data;
        if (error) {
          alert(error.message);
        }
        dispatch({ type: SET_DESCUENTO, payload: descuento });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            alert("Lo siento bebé, ya has agotado este descuento.");
          }
        }
        alert("Lo siento bebé, ese descuento no es válido.");
        dispatch({ type: HIDE_SPINNER_DESCUENTO });
      });
  };

  const createOrder = (course_id, payment_source_id, discountCode) => {
    dispatch({ type: SHOW_SPINNER });
    CheckoutService.postCheckout(course_id, payment_source_id, discountCode)
      .then((res) => {
        const { purchase_id } = res.data;
        navigate(`/thankyou/${purchase_id}`);
        dispatch({ type: HIDE_SPINNER });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          const { data } = error.response;
          if (data) {
            if (data.details) {
              if (data.details[0]) {
                if (
                  data.details[0].code ===
                  "conekta.errors.processing.charge.card_payment.suspicious_behaviour"
                ) {
                  return alert(
                    "Lo siento bebé, nuestro procesador de tarjetas ha rechazado el pago. Si quieres pagar en línea deberá ser por PayPal."
                  );
                } else if (data.details[0].code.includes("formatted")) {
                  return alert(
                    "El formato de la tarjeta no es válido bebé, revisa los datos que ingresaste por favor."
                  );
                } else if (
                  data.details[0].code.includes("insufficient_funds")
                ) {
                  return alert(
                    "Lo siento bebé, esa tarjeta no tiene suficientes fondos."
                  );
                }
              }
            }
          }
          if (error.response.status === 412) {
            if (error.response.data) {
              if (error.response.data.message) {
                return alert(error.response.data.message);
              }
            }
            return alert(
              "Lo siento bebé, se ha alcanzado el límite de personas para este paquete."
            );
          } else if (error.response.status === 409) {
            let message =
              "Lo siento bebé, ya has alcanzado el límite de compras de este paquete.";
            if (error.response.data && error.response.data !== null) {
              if (
                error.response.data.message &&
                error.response.data.message !== null
              ) {
                message = error.response.data.message;
              }
            }
            return alert(message);
          }
        }
      });
  };

  const setPayPal = (course_id, discountCode) => {
    const paypalButton = document.getElementById("paypal-button");
    if (paypalButton !== null) {
      paypalButton.innerHTML = "";
    }

    window.paypal.Button.render(
      {
        env: "production",
        payment: (data, actions) => {
          return CheckoutService.postPayPal(course_id, discountCode)
            .then((res) => {
              return res.data.orderID;
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 412) {
                  if (error.response.data) {
                    if (error.response.data.message) {
                      return alert(error.response.data.message);
                    }
                  }
                } else if (error.response.status === 409) {
                  let message =
                    "Lo siento bebé, ya has alcanzado el límite de compras de este paquete.";
                  if (error.response.data && error.response.data !== null) {
                    if (
                      error.response.data.message &&
                      error.response.data.message !== null
                    ) {
                      message = error.response.data.message;
                    }
                  }
                  return alert(message);
                }
              }
              alert("Lo sentimos. Hubo un error al procesar tu compra.");
            });
        },
        onApprove: (data, actions) => {
          return CheckoutService.capturePayPal(data).then(function (res) {
            const { purchase_id } = res.data;
            success("¡Pago exitoso!");
            navigate(`/thankyou/${purchase_id}`);
            dispatch({ type: HIDE_SPINNER });
          });
        },
      },
      "#paypal-button"
    );
  };

  const showSpinner = () => {
    dispatch({ type: SHOW_SPINNER });
  };

  const hideSpinner = () => {
    dispatch({ type: HIDE_SPINNER });
  };

  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        setPayPal,
        setPaquete,
        createOrder,
        showSpinner,
        hideSpinner,
        setDescuento,
        setDiscountCode,
        setPaymentSource,
        getCourseCheckout,
        validarDescuento,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
