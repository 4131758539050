import { TOKEN_KEY } from "../utils";
import api from "./api";

const route = "/users";

const UsuarioService = {
  getUsuario: () => api.get(route),
  login: () => api.post(`${route}/login`),
  putUsuario: (usuario) => api.put(route, { ...usuario }),
  setToken: (token) => {
    api.defaults.headers.common["Authorization"] = token;
  },
  setSessionToken: (token) => {
    api.defaults.headers.common[TOKEN_KEY] = token;
  },
  postUsuario: (name, last_name, email, phone, uid, signup_reason, city) =>
    api.post(route, {
      name,
      last_name,
      email,
      phone,
      uid,
      signup_reason,
      city,
    }),
};

export default UsuarioService;
