import React, { useContext, useEffect } from "react";
import ErrorAlert from "./components/common/ErrorAlert";
import Modal from "./components/common/Modal";
import SuccessAlert from "./components/common/SuccessAlert";
import { UserContext } from "./context/UserContext";
import Login from "./views/Login";
import Panel from "./views/Panel";
import SignUp from "./views/SignUp";
import { Router } from "@reach/router";
import Loading from "./views/Loading";
import Checkout from "./views/Checkout";
import ThankYou from "./views/ThankYou";
import Home from "./views/Home";
import CheckoutPagos from "./views/CheckoutPagos";
import GraciasCerti from "./views/GraciasCerti";
import Recuperar from "./views/Recuperar";
import PanelCertificacion from "./views/PanelCertificacion";
import HowToLatina from "./views/HowToLatina";
import LatinaInstructor from "./views/LatinaInstructor";

const Main = () => {
  const { user, userLoggedIn } = useContext(UserContext);

  useEffect(() => {
    userLoggedIn();
  }, []);

  return (
    <div>
      <Router>
        <Home path="/" default />
        <Login path="/login" />
        <SignUp path="/signup" />
        <Recuperar path="/recuperar" />
        <HowToLatina path="/howtolatina" />
        <LatinaInstructor path="/instructor" />
        <Checkout path="/checkout/course/:course_id" />
        <CheckoutPagos path="/checkout/certificacion" />
        <ThankYou path="/thankyou/:purchase_id" />
        <GraciasCerti path="/gracias" />
        {user !== null ? (
          <>
            <Panel path="/mylatina/*" />
            <PanelCertificacion path="/mycertification/*" />
          </>
        ) : (
          <Loading path="/mylatina/*" />
        )}
      </Router>
      <Modal />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;
