import {
  LOGIN,
  LOGOUT,
  SHOW_SPINNER,
  HIDE_SPINNER,
  USER_CREATED,
  SHOW_ALERT,
  SET_PROPERTY_USER,
  SET_PROPERTY_LOGIN,
  REATTEMPT_LOGIN,
} from "../types";

export default (state, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return { ...state, user: payload, spinner: false, reattempt: false };
    case LOGOUT:
      return { ...state, user: null, reattempt: false };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case USER_CREATED:
      return { ...state, created: true, reattempt: false };
    case SHOW_ALERT:
      return { ...state, error: payload };
    case SET_PROPERTY_USER: {
      const { key, value } = payload;
      const user = { ...state.user };
      user[key] = value;
      return { ...state, user };
    }
    case SET_PROPERTY_LOGIN:
      const { key, value } = payload;
      return { ...state, [key]: value };
    case REATTEMPT_LOGIN: {
      return { ...state, reattempt: true };
    }
    default:
      return { ...state };
  }
};
