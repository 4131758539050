import React from "react";
import { S3_ENDPOINT } from "../../utils";
import amex from "../../assets/images/amex.png";
import visa from "../../assets/images/visa.png";
import paypal from "../../assets/images/paypal.png";
import mastercard from "../../assets/images/mastercard.png";

const Footer = () => {
  return (
    <div className="bg-dark py-5">
      <div className="container-fluid p-5 text-white">
        <div className="row">
          <div className="col-12 col-md-4 my-3">
            <img
              src={`${S3_ENDPOINT}/latina-cert-logo.png`}
              className="logo-footer"
            />
          </div>
          <div className="col-12 col-md-4 my-3">
            <h4 className="text-primary text-montserrat bold">CONTACTO</h4>
            <p>
              <i className="fa fa-map-marker-alt text-primary me-2"></i>
              Monterrey, Nuevo León.
            </p>
            <p>
              <i className="fa fa-envelope text-primary me-2"></i>{" "}
              latinatribe@gmail.com
            </p>
          </div>
          <div className="col-12 col-md-4 my-3">
            <h4 className="text-primary text-montserrat bold">ACEPTAMOS</h4>
            <img src={amex} className="payment-method me-2" />
            <img src={mastercard} className="payment-method me-2" />
            <img src={visa} className="payment-method me-2" />
            <img src={paypal} className="payment-method me-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
