import React from "react";

const LessonInfo = ({ lesson, toggleFavorite, toggleComplete }) => {
  const renderInstructor = () => {
    if (lesson.instructor && lesson.instructor !== null) {
      return lesson.instructor.name;
    }
  };

  const renderMetadata = () => {
    if (lesson.author !== null && lesson.author !== "") {
      return (
        <p className="px-0 mb-0">
          <span className="me-3">{lesson.author}</span>
          <span
            className={`badge badge-pill ${
              String(lesson.level).toLowerCase() === "principiante"
                ? "bg-success"
                : "bg-warning"
            }`}
          >
            {lesson.level}
          </span>
          <span className="mx-3">{renderInstructor()}</span>
        </p>
      );
    }
  };
  const renderContent = () => {
    if (lesson && lesson !== null) {
      const isFavorite = lesson.favorites.length > 0;
      const isComplete = lesson.completed.length > 0;
      return (
        <div className="container-fluid">
          <div className="row pb-2 align-items-center border-bottom">
            <div className="col-12 col-md-6 mb-3">
              <h2 className="mb-0 px-0">{lesson.name}</h2>
            </div>
            <div className="col-12 col-md-6 mb-3 text-right pe-0">
              <button
                className={`btn btn-${
                  isComplete ? "success" : "light"
                } border me-2`}
                onClick={toggleComplete}
              >
                <i className="fa fa-check"></i>
              </button>
              <button
                className={`btn btn-${isFavorite ? "primary" : "light"} border`}
                onClick={toggleFavorite}
              >
                <i className="fa fa-heart"></i>
              </button>
            </div>
          </div>
          {renderMetadata()}
        </div>
      );
    }
  };

  return (
    <div className="card p-3 no-scale shadow-sm border mb-3">
      {renderContent()}
    </div>
  );
};

export default LessonInfo;
