import React from "react";
import InvoiceCard from "./InvoiceCard";

const InvoicesTable = ({ invoices, courses }) => {
  const renderOrdenes = () => {
    if (Array.isArray(invoices)) {
      if (invoices.length === 0) {
        return <p className="mt-3">No has hecho ninguna compra.</p>;
      }
      return invoices.map((invoice) => (
        <InvoiceCard
          key={invoice.invoice_id}
          invoice={invoice}
          courses={courses}
        />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light border bold py-2">
            <th>#ID</th>
            <th>Compra</th>
            <th>Total</th>
            <th>Estado</th>
            <th>Fecha y Hora</th>
            <th>Expira en</th>
            <th>Detalles</th>
          </tr>
        </thead>
        <tbody>{renderOrdenes()}</tbody>
      </table>
    </div>
  );
};

export default InvoicesTable;
