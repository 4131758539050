import React, { useContext, useEffect, useState } from "react";
import VideoCard from "../../components/videos/VideoCard";
import { VideosContext } from "../../context/VideosContext";
import LatestVideo from "../../components/videos/LatestVideo";
import { UserContext } from "../../context/UserContext";
import FilterSection from "../../components/videos/FilterSection";
import { FiltersContext } from "../../context/FiltersContext";
import { Link } from "@reach/router";
import { ClassCategoryContext } from "../../context/ClassCategoryContext";

const Videos = () => {
  const [page, setPage] = useState(1);
  const { user, getUsuario } = useContext(UserContext);
  const { showFavorites, video_type_id, class_category_id } =
    useContext(FiltersContext);

  const {
    videos,
    favoritos,
    purchase_needed,
    getVideos,
    clearVideos,
    agregarFavorito,
    eliminarFavorito,
  } = useContext(VideosContext);

  const { getClassCategories } = useContext(ClassCategoryContext);

  useEffect(() => {
    getUsuario();
    getClassCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearVideos();
    getVideos({ video_type_id, class_category_id, page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getVideosRender = () => {
    if (Array.isArray(videos)) {
      return videos.sort((a, b) => (a.message_id > b.message_id ? -1 : 1));
    }
  };

  const renderVideos = () => {
    if (favoritos && favoritos !== null) {
      if (favoritos.length === 0) {
        return (
          <p>
            No tienes videos favoritos
            {video_type_id ? " para esta búsqueda" : ""}.
          </p>
        );
      }
      const videosRender = [...favoritos];
      return videosRender.map((video) => (
        <VideoCard
          key={video.video_id}
          video={video}
          user={user}
          agregarFavorito={agregarFavorito}
          eliminarFavorito={eliminarFavorito}
        />
      ));
    }
    let videosRender = getVideosRender();
    if (Array.isArray(videosRender)) {
      if (videosRender.length === 0) {
        return <p>Aún no hay videos de esta categoría.</p>;
      }
      return videosRender.map((video) => (
        <VideoCard
          key={video.video_id}
          video={video}
          user={user}
          agregarFavorito={agregarFavorito}
          eliminarFavorito={eliminarFavorito}
        />
      ));
    }
    if (purchase_needed)
      return <p>Tu membresía de instructora se encuentra inactiva.</p>;
    if (!purchase_needed) return <div className="spinner-border mx-3"></div>;
  };

  const renderLatest = () => {
    if (showFavorites) {
      if (favoritos && favoritos !== null) {
        const video = favoritos[0];
        if (video) {
          return <LatestVideo video={video} />;
        }
      }
    } else {
      if (videos && videos !== null) {
        const video = videos[0];
        if (video) {
          return <LatestVideo video={video} />;
        }
      }
    }
    if (purchase_needed) {
      return (
        <div className="container-fluid px-0">
          <p>Lo sentimos, no cuentas con acceso a Latina At Home. </p>
          <Link className="btn btn-primary" to="/checkout/15">
            Ir a Shop
          </Link>
        </div>
      );
    }
  };

  const renderPages = () => {
    if (!purchase_needed)
      return (
        <div>
          <div
            className="btn btn-light shadow-sm mx-2"
            disabled={page === 1}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            {"<"}
          </div>
          <span className="mx-2">{page}</span>
          <div
            className="btn btn-light shadow-sm mx-2"
            onClick={() => setPage(page + 1)}
          >
            {">"}
          </div>
        </div>
      );
  };

  return (
    <div className="container-fluid">
      <h1 className="display-5">Videos Instructora</h1>
      {renderLatest()}
      {!purchase_needed && <FilterSection />}
      <div className="row">{renderVideos()}</div>
      <div className="container-fluid text-right px-0">{renderPages()}</div>
    </div>
  );
};

export default Videos;
