import React, { useContext, useEffect } from "react";
import HomeCourseCard from "../components/courses/HomeCourseCard";
import { CoursesContext } from "../context/CoursesContext";
import Footer from "../components/global/Footer";
import Navbar from "../components/global/Navbar";
import { S3_ENDPOINT } from "../utils";
import "./Home.css";

const StudioLanding = () => {
  const { courses, getCourses } = useContext(CoursesContext);

  useEffect(() => {
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCourses = () => {
    if (Array.isArray(courses)) {
      return courses.map((course) => (
        <div className="col-12 col-md-6 px-4 mt-5">
          <HomeCourseCard key={course.course_id} course={course} />
        </div>
      ));
    }
  };

  return (
    <div className="home-page bg-dark">
      <Navbar />
      <div id="video" className="row video-row">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="d-block slideshow-img w-100 mw-100"
                src={`${S3_ENDPOINT}/home-cover.jpeg`}
                alt="First slide"
              />
              <div className="slideshow-text">
                <div className="row h-100">
                  <div className="col-12 col-md-6 col-xl-4">
                    <div className="row h-600 align-items-center">
                      <div className="slideshow-content">
                        <img
                          id="certified-logo"
                          alt="logo certification"
                          src={`${S3_ENDPOINT}/get-latina-certified.png`}
                          className="mb-4"
                        />
                        <div className="text-lg">
                          <p className="bold mb-0">
                            ¡Conviértete en instructora del método Latina
                            Tribe&copy;!
                          </p>
                          <p>
                            Emprende tu propio negocio y empodera a tu comunidad
                            a través de tu pasión por el baile.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-8">
                            <a
                              href="#trainings"
                              className="btn btn-primary btn-lg mb-3 w-100 bold"
                            >
                              UPCOMING TRAININGS
                            </a>
                          </div>
                          <div className="col-12 col-md-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-8 h-600 hide-mobile"></div>
                </div>
              </div>
              <div className="carousel-overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <section id="welcome">
        <div className="container">
          <div
            style={{ height: "60vh", maxWidth: 900, margin: "auto" }}
            className="row align-items-center"
          >
            <div>
              <img
                alt="welcome to the tribe"
                src={`${S3_ENDPOINT}/welcome-tribe.png`}
                className="mw-75 w-75 m-auto d-block mb-5"
              />
              <p className="text-center">
                Latina es un método de dance fitness enfocado al empoderamiento
                de la mujer. Nuestro método combina ejercicios tradicionales de
                fitness escondidos dentro de coreografías retadoras y divertidas
                al ritmo de la música de moda.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="trainings" className="py-5 text-center">
        <div className="container-fluid">
          <h2 className="text-primary mb-5">Upcoming Trainings</h2>
          <div className="row">{renderCourses()}</div>
        </div>
      </section>
      <section id="testimonios" className="py-5">
        <div className="container-fluid">
          <h2 className="text-center text-primary mb-5">
            Latina Instructors Around The World
          </h2>
          <div className="row">
            <div className="col-12 col-md-4 mt-5">
              <div className="card m-auto">
                <img
                  alt="testimonio aris"
                  src={`${S3_ENDPOINT}/aris-parra.jpg`}
                  className="mw-100 w-100 m-auto my-0 d-block"
                />
                <div className="content">
                  <p>
                    Haberme certificado de Latina ha sido a dream come true,
                    Nunca imaginé poder llevar Latina a otro país, y no solo el
                    dar clases, también el estar creando una comunidad hermosa
                    de latinas viviendo lejos de sus países, y no solo latinas,
                    una comunidad de mujeres con el mismo propósito de
                    empoderarnos unas a las otras!! Me encanta compartir mi amor
                    por Latina, y estoy feliz de poder transmitirles mi pasión
                    por lo que hago.
                  </p>
                  <p className="text-primary mb-0 bold">Aris Parra</p>
                  <p className="text-primary bold">SW, Florida</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-5">
              <div className="card m-auto">
                <img
                  alt="testimonio melisa"
                  src={`${S3_ENDPOINT}/melisa-guzman.jpg`}
                  className="mw-100 w-100 m-auto my-0 d-block"
                />
                <div className="content">
                  <p>
                    Mi primera clase la tomé en el 2021 en la sala, gracias a mi
                    hermana porque me regaló Latina at home. Estaba en un punto
                    en el que ya no sabía qué hacer para sentirme y verme mejor
                    para mi. Venía regresando de un intercambio en la India
                    fallido por la pandemia con cero amor propio y mucha
                    ansiedad. Bailar me reconstruyó y me sanó en muchos
                    aspectos. Después de mi primer mes como alumna supe que si
                    de algo me quería certificar y dar clases algún día, iba a
                    ser de Latina.
                  </p>
                  <p className="text-primary mb-0 bold">Melisa Guzmán</p>
                  <p className="text-primary bold">Puebla, México</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-5">
              <div className="card m-auto">
                <img
                  alt="testimonio paola"
                  src={`${S3_ENDPOINT}/paola-hinojosa.jpg`}
                  className="mw-100 w-100 m-auto my-0 d-block"
                />
                <div className="content">
                  <p>
                    Desde antes de que existieran las certificaciones, yo sabía
                    que había un lugar muy especial para Latina Tribe en Canadá
                    y yo moría por impartirlo. El volverme coach y ver las
                    mismas ganas y emoción de mis alumnas que yo he sentido como
                    alumna confirma que volverme coach ha sido de las mejores
                    decisiones que he tomado.
                  </p>
                  <p className="text-primary mb-0 bold">Paola Hinojosa</p>
                  <p className="text-primary bold">Calgary, Canadá</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default StudioLanding;
