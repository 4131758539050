import React, { useReducer, createContext } from "react";
import InvoicesReducer from "../reducers/InvoicesReducer";
import InvoicesService from "../services/InvoicesService";
import { INVOICES_RECEIVED } from "../types/invoices";

const initialState = {
  invoices: null,
  invoice: null,
};

export const InvoicesContext = createContext(initialState);

export const InvoicesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InvoicesReducer, initialState);
  const getInvoices = () => {
    InvoicesService.getInvoices().then((res) => {
      const { invoices } = res.data;
      dispatch({ type: INVOICES_RECEIVED, payload: invoices });
    });
  };
  return (
    <InvoicesContext.Provider value={{ ...state, getInvoices }}>
      {children}
    </InvoicesContext.Provider>
  );
};
