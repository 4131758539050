import React, { useContext } from "react";
import { LessonsContext } from "../../context/LessonsContext";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";

const LessonRow = ({ currentLesson }) => {
  const { lesson, selectLesson } = useContext(LessonsContext);

  const isComplete = () => {
    return (
      currentLesson.completed_lesson && currentLesson.completed_lesson !== null
    );
  };

  const isSelected = () => {
    if (currentLesson !== null && lesson !== null) {
      return currentLesson.lesson_id === lesson.lesson_id;
    }
  };

  const renderImage = () => {
    let { media, file } = currentLesson;
    if (file && file !== null) {
      return (
        <div className="col-3">
          <img
            src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
            className="mw-100 w-100"
          />
        </div>
      );
    }
    if (media) {
      if (Array.isArray(media)) {
        media = media
          .filter((media) => media.file !== null)
          .find((media) =>
            ["jpg", "png", "jpeg", "gif"].includes(media.file.type)
          );
        if (!media) {
          media = null;
        }
      }
    }
  };

  return (
    <Link
      className={`container-fluid hover-accent text-dark no-decoration ${
        isSelected() ? "bg-accent" : ""
      }`}
      to={`./lessons/${currentLesson.lesson_id}`}
    >
      <div className="card p-2 mb-2">
        <div className="row mx-0 align-items-center">
          {renderImage()}
          <div className="col-8">
            <h3 className="bold mb-0">
              {isComplete() && <i className="fa fa-check"></i>}
              {currentLesson.name}
            </h3>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default LessonRow;
