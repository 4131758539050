import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { CoursesContext } from "../../context/CoursesContext";
import Sections from "../sections/Sections";
import { S3_ENDPOINT } from "../../utils";

const CourseNavigation = () => {
  const { course } = useContext(CoursesContext);

  useEffect(() => {
    let panel = document.getElementsByClassName("main-panel")[0];
    if (panel !== null) {
      panel.classList.add("mx-0");
    }
    return () => {
      panel.classList.remove("mx-0");
    };
  }, []);

  return (
    <div className="side-menu course-navigation container px-0 py-3">
      <Link to="/mylatina" className="p-3 d-block">
        {course.course_id === 4 ? (
          <img
            src={`${S3_ENDPOINT}/latina-cert-logo.png`}
            className="mw-100 w-100 d-block m-auto"
          />
        ) : (
          <img
            src={`${S3_ENDPOINT}/howtolatina-logo.png`}
            className="mw-100 w-100 d-block m-auto"
          />
        )}
      </Link>
      <Sections course_id={course.course_id} />
    </div>
  );
};

export default CourseNavigation;
