import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";
import StatusBadge from "../global/StatusBadge";

const PurchaseCard = ({ purchase, handleCancel }) => {
  const renderCancel = () => {
    if (purchase.subscription_id !== null && purchase.status === "active") {
      return (
        <button
          onClick={() => handleCancel(purchase)}
          className="btn btn-sm btn-outline-danger"
        >
          <i className="fa fa-times"></i>
        </button>
      );
    }
  };
  const renderCourse = () => {
    if (purchase && purchase !== null) {
      let { amount } = purchase;
      amount = String(amount);
      if (amount === "499" || amount === "0") {
        return "Instructor Membership";
      }
      if (amount === "3000" || amount === "6450") {
        return "Latina Certification";
      }
      const { course_purchase } = purchase;
      if (course_purchase && course_purchase !== null) {
        const { course } = course_purchase;
        if (course && course !== null) {
          return course.name;
        }
      }
    }
  };
  const renderExpiration = () => {
    if (purchase.expiration_date !== null) {
      return moment(purchase.expiration_date).format("DD MMM YYYY HH:mm");
    }
    if (purchase.subscription_id !== null && purchase.status === "cancelled") {
      let free_trial_cancel = moment(purchase.createdAt);
      if (purchase.free_trial_length !== null) {
        free_trial_cancel.add(purchase.free_trial_length, "days");
      }
      let cancel_expiration = moment(purchase.updatedAt).add(
        purchase.subscription_interval,
        purchase.subscription_period
      );
      let expiration_date = cancel_expiration;
      if (free_trial_cancel.isAfter(cancel_expiration)) {
        expiration_date = free_trial_cancel;
      }
      return expiration_date.format("DD MMM YYYY");
    }
  };
  return (
    <tr className="border">
      <td>{purchase.purchase_id}</td>
      <td>{renderCourse()}</td>
      <td>
        {"$"}
        {formatMonto(purchase.amount)}
        {" MXN"}
      </td>
      <td>
        <StatusBadge status={purchase.status} order_id={purchase.order_id} />
      </td>
      <td className="datetime">
        {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}
      </td>
      <td className="datetime">{renderExpiration()}</td>
      <td className="datetime">{renderCancel()}</td>
    </tr>
  );
};

export default PurchaseCard;
