import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import { InvoicesContext } from "../context/InvoicesContext";
import { CoursesContext } from "../context/CoursesContext";
import InvoicesTable from "../components/invoices/InvoicesTable";
import PurchasesTable from "../components/purchases/PurchasesTable";
import { ModalContext } from "../context/ModalContext";
import CancelPurchaseForm from "../components/purchases/CancelPurchaseForm";

const Pagos = () => {
  const { purchases, getPurchases, cancelPurchase } =
    useContext(PurchasesContext);

  const { invoices, getInvoices } = useContext(InvoicesContext);

  const { courses, getCourses } = useContext(CoursesContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPurchases();
    getInvoices();
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = (purchase) => {
    modalComponent(
      "Cancelar Compra",
      <CancelPurchaseForm purchase={purchase} cancelPurchase={cancelPurchase} />
    );
  };

  return (
    <div className="container-fluid pt-4">
      <div className="mx-0 row align-items-center my-3">
        <div className="container-fluid px-0">
          <h4>Mis Compras</h4>
        </div>
      </div>
      <div className="alert alert-pink alert-light border" role="alert">
        <i className="fa fa-info-circle me-2"></i> Si tienes una pregunta sobre
        una compra, contáctanos a @latina.tribe e indicanos tu{" "}
        <span className="bold">correo electrónico y #ID de compra</span>
      </div>
      <PurchasesTable
        handleCancel={handleCancel}
        purchases={purchases}
        courses={courses}
      />
      <div className="mx-0 row align-items-center my-3">
        <div className="container-fluid px-0">
          <h4>Mis Cargos Recurrentes</h4>
        </div>
      </div>
      <div className="alert alert-pink alert-light border" role="alert">
        <i className="fa fa-info-circle me-2"></i> Si tienes una pregunta sobre
        un cargo, contáctanos a @latina.tribe e indicanos tu{" "}
        <span className="bold">correo electrónico y #ID de cargo</span>
      </div>
      <InvoicesTable invoices={invoices} courses={courses} />
    </div>
  );
};

export default Pagos;
