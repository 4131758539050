import { LESSONS_RECIBIDAS, SET_LESSON } from "../types/lessons";

export default (state, { type, payload }) => {
  switch (type) {
    case SET_LESSON:
      return { ...state, lesson: payload };
    case LESSONS_RECIBIDAS:
      return { ...state, lessons: payload };
    default:
      return { ...state };
  }
};
