import { METODOS_RECIBIDOS } from "../types";

export default (state, { type, payload }) => {
  switch (type) {
    case METODOS_RECIBIDOS:
      return { ...state, metodos: payload };
    default:
      return { ...state };
  }
};
