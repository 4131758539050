import { Router } from "@reach/router";
import React, { useContext, useEffect } from "react";
import CourseInfo from "../components/courses/CourseInfo";
import CourseNavigation from "../components/courses/CourseNavigation";
import SingleLesson from "./SingleLesson";
import SingleSection from "../components/sections/SingleSection";
import { CoursesContext } from "../context/CoursesContext";
import BottomNavbar from "../components/navigation/BottomNavbar";

const SingleCourse = ({ course_id }) => {
  const { course, clearCourse, getSingleCourse } = useContext(CoursesContext);

  useEffect(() => {
    clearCourse();
    getSingleCourse(course_id);
    return clearCourse;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course_id]);

  const renderCourse = () => {
    if (course && course !== null) {
      return (
        <div className="vh-100 overflow-hidden">
          <CourseNavigation course_id={course.course_id} />
          <div className="main-panel vh-100 bg-light">
            <Router>
              <CourseInfo path="/" />
              <SingleSection path="/sections/:section_id" />
              <SingleLesson path="/sections/:section_id/lessons/:lesson_id" />
            </Router>
          </div>
          <BottomNavbar />
        </div>
      );
    }
    return (
      <div className="p-5">
        <div className="spinner-border"></div>
      </div>
    );
  };

  return <div className="container-fluid px-0">{renderCourse()}</div>;
};

export default SingleCourse;
