import moment from "moment";

moment.locale("es", {
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
  weekdaysShort: "Lun_Mar_Mie_Jue_Vie_Sab_Dom".split("_"),
});

export const TOKEN_KEY = "Device-Token";

export const API_KEY = "AIzaSyD_0F-t4VQvP9WHWcb7-E90M9WGzWHLUbU";

const stripe_dev_key =
  "pk_test_51LfuZgE9ZKfE9imp8ofFB7LW0jJiIBAxKY0hVXGerFBFcvKYw9SizwRjxW02OenPAmIo1z0fqDYQd9aOh7Rfja2300OT1xMzv6";

const stripe_live_key =
  "pk_live_51LfuZgE9ZKfE9impVSQd1amMp5g7NxlPAMUznuCBuiVIYROGuv4KUeccOrpgmHJguANojLxgB9Dx26BsvoFkvgOB00ALmrZr6F";

export const STRIPE_KEY =
  process.env.NODE_ENV === "development" ? stripe_dev_key : stripe_live_key;

const conekta_dev_key = "key_A0NspbG0FA12sC4fTReuXcX";

const conekta_live_key = "key_TSxn7pq1wdfKEewXypuuqBg";

export const CONEKTA_KEY =
  process.env.NODE_ENV === "development" ? conekta_dev_key : conekta_live_key;

export const BASE_URL =
  (process.env.NODE_ENV === "development"
    ? "http://localhost:4001"
    : "https://instructor.latinatribe.mx") + "/api";

export const S3_ENDPOINT = "https://latinacerts.s3.us-west-1.amazonaws.com";

export const searchRows = (query, rows) => {
  if (!rows) return;
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (subcolumn.toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (String(row[column]).toLowerCase().includes(query)) {
          return row;
        }
      } else if (String(row[column]) === query) {
        return row;
      } else if (Number(row[column]) === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (args[key] && args[key] !== null && args[key] !== "") {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `&${array.join("&")}`;
    }
  }
  return "";
};

export const calcularTotal = (productos) => {
  if (productos && productos !== null) {
    let total = 0;
    productos.forEach((producto) => {
      total += producto.precio * producto.cantidad;
    });
    return total;
  }
  return 0;
};

export function formatMonto(monto) {
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const hideModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#modal";
    newButton.id = "main-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-target", "#modal");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#modal");
    newButton.style.visibility = "hidden";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const menuitems = [
  {
    name: "How To Latina",
    handle: "/mylatina/courses/1",
  },
  {
    name: "Instructor",
    handle: "/mylatina/courses/4",
  },
  {
    name: "Evento",
    handle: "https://forms.gle/2x1vUonWDW16xhnA6",
  },
];

export const cuenta = [
  {
    name: "Mi Información",
    handle: "/mylatina/informacion",
  },
  {
    name: "Mis Compras",
    handle: "/mylatina/pagos",
  },
  {
    name: "Mis Métodos de Pago",
    handle: "/mylatina/metodos",
  },
];

export const adminitems = [
  {
    name: "Clases",
    handle: "/mylatina/admin/asistentes",
  },
  {
    name: "Clientes",
    handle: "/mylatina/admin/clientes",
  },
];

export const coachitems = [
  {
    name: "Clases",
    handle: "/mylatina/admin/asistentes",
  },
];

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
    ? "visa"
    : "mastercard";

export const categories = {
  dance: [11, 12, 13, 15],
  workout: [14, 15],
  presenciales: [11742, 11743, 11744, 11745],
};

export const getCompradasPresenciales = (paquetes) => {
  const package_ids = categories.presenciales;
  const customer_classes = paquetes.filter((class_category) =>
    package_ids.includes(class_category.package_class_id)
  );
  let total = 0;
  customer_classes.forEach((purchase) => {
    total += purchase.available_class;
  });
  return total;
};

export const getPresencialesVigentes = (paquetes) => {
  const package_ids = categories.presenciales;
  const customer_classes = paquetes.filter(
    (class_category) =>
      package_ids.includes(class_category.package_class_id) &&
      moment(class_category.created_at)
        .add(class_category.package_days, "days")
        .isAfter(moment())
  );
  let total = 0;
  customer_classes.forEach((purchase) => {
    total += purchase.available_class;
  });
  return total;
};

export const getVigencia = (handle, user) => {
  const paquete = user.vigencias.find(
    (class_category) => class_category.handle === handle
  );
  if (paquete) {
    return paquete.expiration;
  }
};

export const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const getCoursePrice = (curso) => {
  const { sale_price, price } = curso;
  return sale_price && sale_price !== null && sale_price !== ""
    ? sale_price
    : price;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNumber = (input_str) => {
  var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

  return re.test(input_str);
};

export const handleCheckoutError = (error, callback) => {
  if (error.response) {
    const { data } = error.response;
    if (data) {
      if (data.error) {
        const { code, decline_code } = data.error;
        if (code && code !== null) {
          let message = "Lo siento bebé, hubo un error al procesar tu tarjeta.";
          if (code === "card_declined") {
            if (decline_code === "insufficient_funds") {
              message =
                "Lo siento bebé, tu tarjeta no tiene fondos suficientes.";
            } else {
              message =
                "Lo siento bebé, tu compra ha sido rechazada por el banco. Intenta más tarde o con otra tarjeta.";
            }
          } else if (code === "expired_card") {
            message = "Lo siento bebé, tu tarjeta ha expirado.";
          } else if (code === "incorrect_number") {
            message = "Lo siento bebé, el número de tu tarjeta es incorrecto.";
          }
          return callback(message);
        }
      }
    }
    if (error.response.status === 412) {
      if (error.response.data) {
        if (error.response.data.message) {
          return callback(error.response.data.message);
        }
      }
      return callback("Lo sentimos. Este evento especial se ha llenado.");
    } else if (error.response.status === 409) {
      let message =
        "Lo siento bebé, ya has alcanzado el límite de compras de esta certificación.";
      if (error.response.data && error.response.data !== null) {
        if (
          error.response.data.message &&
          error.response.data.message !== null
        ) {
          message = error.response.data.message;
        }
      }
      return callback(message);
    } else if (error.response.status === 402) {
      return callback(
        "Lo sentimos. Tu tarjeta fue rechazada por fondos insuficientes."
      );
    }
  }
  callback("Intenta de nuevo o contáctanos para más información.");
};
