import React, { useState, useEffect } from "react";
import { S3_ENDPOINT } from "../../utils";
import BirthdateInput from "../common/BirthdateInput";

const UserForm = ({ user, handleCancel, handleSubmit, setPropiedadUser }) => {
  const [changeImage, setChangeImage] = useState(false);
  const [picture, setPicture] = useState(null);

  const {
    name,
    city,
    phone,
    last_name,
    birthdate,
    instagram,
    instagram_business,
  } = user;

  useEffect(() => {
    if (user !== null) {
      if (user.picture && user.picture !== null && picture === null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPicture(e.target.result);
        };
        reader.readAsDataURL(user.picture);
      }
    }
  }, [user]);

  const handleFiles = (e) => {
    const file = e.target.files[0];
    setPropiedadUser("picture", file);
  };

  const renderImage = () => {
    if (picture !== null) {
      return (
        <div className="col-4 col-md-4">
          <img
            src={picture}
            className="video-form-thumbnail d-block my-3"
            alt="Profile"
          />
        </div>
      );
    }
  };

  const renderImageForm = () => {
    if (changeImage || isNaN(user.file_id) || user.file_id === null) {
      return (
        <input
          type="file"
          className="form-control mb-3"
          onChange={handleFiles}
        />
      );
    }
    return (
      <div className="row">
        <div className="col-4">
          <img
            src={`${S3_ENDPOINT}/${user.file.name}.${user.file.type}`}
            className="mw-100 w-100 d-block m-auto"
          />
        </div>
        <div className="col-6">
          <button
            className="btn btn-outline-light me-3"
            onClick={() => setChangeImage(true)}
          >
            Cambiar Foto
          </button>
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {renderImage()}
        <div className="col-8 col-md-8">
          <label>Foto</label>
          {renderImageForm()}
        </div>
      </div>
      <label>Nombre:</label>
      <input
        type="text"
        className="form-control mb-3"
        value={name}
        onChange={(e) => setPropiedadUser("name", e.target.value)}
      />
      <label>Apellidos:</label>
      <input
        type="text"
        className="form-control mb-3"
        value={last_name}
        onChange={(e) => setPropiedadUser("last_name", e.target.value)}
      />
      <label>Instagram Personal:</label>
      <input
        type="text"
        className="form-control mb-3"
        value={instagram}
        onChange={(e) => setPropiedadUser("instagram", e.target.value)}
      />
      <label>Instagram Business:</label>
      <input
        type="text"
        className="form-control mb-3"
        value={instagram_business}
        onChange={(e) => setPropiedadUser("instagram_business", e.target.value)}
      />
      <label>Ciudad:</label>
      <input
        type="text"
        className="form-control mb-3"
        value={city}
        onChange={(e) => setPropiedadUser("city", e.target.value)}
      />
      <label>Fecha de Nacimiento:</label>
      <BirthdateInput
        value={birthdate}
        modifier={(value) => setPropiedadUser("birthdate", value)}
      />
      <label>Telefono Celular</label>
      <input
        type="tel"
        className="form-control mb-3"
        value={phone}
        onChange={(e) => setPropiedadUser("phone", e.target.value)}
      />
      <div className="row mt-4">
        <div className="col col-md-6">
          <button type="submit" className="btn btn-primary">
            Guardar
          </button>
        </div>
        <div className="col col-md-6 text-end">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
