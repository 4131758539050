import React, { createContext, useContext, useReducer } from "react";
import ClassCategoryReducer from "../reducers/ClassCategoryReducer";
import ClassCategoryService from "../services/ClassCategoryService";
import {
  CLASS_CATEGORIES_RECIBIDAS,
  CLASS_CATEGORY_RECIBIDA,
} from "../types/class_categories";
import { ModalContext } from "./ModalContext";

const initialState = {
  class_categories: null,
  class_category: null,
};

export const ClassCategoryContext = createContext(initialState);

export const ClassCategoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClassCategoryReducer, initialState);

  const { success } = useContext(ModalContext);

  const getClassCategories = () => {
    ClassCategoryService.getClassCategories().then((res) => {
      const { class_categories } = res.data;
      dispatch({ type: CLASS_CATEGORIES_RECIBIDAS, payload: class_categories });
    });
  };

  const getAvailableClassCategories = () => {
    ClassCategoryService.getAvailableClassCategories().then((res) => {
      const { class_categories } = res.data;
      dispatch({
        type: CLASS_CATEGORIES_RECIBIDAS,
        payload: class_categories,
      });
    });
  };

  const getClassCategory = (class_category_id) => {
    ClassCategoryService.getClassCategory(class_category_id).then((res) => {
      const { class_category } = res.data;
      dispatch({ type: CLASS_CATEGORY_RECIBIDA, payload: class_category });
    });
  };

  return (
    <ClassCategoryContext.Provider
      value={{
        ...state,
        getClassCategories,
        getClassCategory,
        getAvailableClassCategories,
      }}
    >
      {children}
    </ClassCategoryContext.Provider>
  );
};
