import React, { useContext, useEffect, useState } from "react";
import StripeCheckout from "../components/common/StripeCheckout";
import logo_latina from "../assets/images/latina_logo1.png";
import SignUpForm from "../components/auth/SignUpForm";
import LoginForm from "../components/auth/LoginForm";
import { UserContext } from "../context/UserContext";
import { S3_ENDPOINT } from "../utils";
import { Link } from "@reach/router";

const CheckoutPagos = () => {
  //Singup and Login
  const [login, setLogin] = useState(false);

  //Payments
  const { user } = useContext(UserContext);

  useEffect(() => {
    //getPaquete(class_package_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderCreateAccount = () => {
    if (user === null) {
      if (login) {
        return <LoginForm />;
      }
      return <SignUpForm signInAction={() => setLogin(true)} />;
    }
  };

  const renderPago = () => {
    if (user !== null) {
      return (
        <>
          <h2>Forma de Pago</h2>
          <div className="card border shadow-sm no-scale my-3">
            <StripeCheckout element_id="certificacion" />
          </div>
        </>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row pb-3 bg-dark pt-3">
        <div className="container-fluid mb-2 pb-2 border-bottom ">
          <div className="row align-items-center py-2">
            <div className="col-8 col-md-10">
              <h1 className="text-white">Checkout</h1>
            </div>
            <div className="col-4 col-md-2 text-right">
              <Link to="/">
                <img
                  src={logo_latina}
                  alt="latina logo"
                  className="mw-100 w-100"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row bg-light pt-4 pb-5 h-80">
        <div className="col-12 col-md-4 my-2">
          <div className="card p-3 mb-3 no-scale shadow-sm">
            <img
              alt="certification"
              src={`${S3_ENDPOINT}/certi-foto.png`}
              className="mw-50 w-100 d-block m-auto mb-3"
            />
            <div>
              <h4>Latina Certificación</h4>
              <p className="large">Paga Ahora $3,000 MXN</p>
              <p>Después: $6,450 MXN cada quincena, por 2 quincenas</p>
              <p>Total: $15,900 MXN</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 my-2">
          {renderCreateAccount()}
          {renderPago()}
        </div>
        <div className="container-fluid">
          <div>
            <h4>Acerca de tu Facturación</h4>
            <p>
              Se te realizará un cargo automático a tu tarjeta de crédito o
              débito en 15 días de $6,450 MXN y otro en 15 días después de
              $6,450 MXN.
            </p>
            <p>
              Si tu tarjeta no pasa por alguna razón, te contactaremos. Si tu
              certificación no está liquidada para el 15 de Noviembre, no podrás
              asistir a la certificación.
            </p>
          </div>
        </div>
      </div>
      <div className="row bg-dark text-white py-2">
        <div className="container-fluid">
          <div className="col col-md-6"></div>
          <div className="col col-md-6"></div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPagos;
