import {
  COURSE_PURCHASES_RECEIVED,
  PURCHASES_RECEIVED,
  SET_PURCHASE,
} from "../types";

export default (state, { type, payload }) => {
  switch (type) {
    case PURCHASES_RECEIVED:
      return { ...state, purchases: payload };
    case SET_PURCHASE:
      return { ...state, purchase: payload };
    case COURSE_PURCHASES_RECEIVED:
      return { ...state, course_purchases: payload };
    default:
      return { ...state };
  }
};
