import { CheckoutProvider } from "./context/CheckoutContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { CoursesProvider } from "./context/CoursesContext";
import { FiltersProvider } from "./context/FiltersContext";
import { LessonsProvider } from "./context/LessonsContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ModalProvider } from "./context/ModalContext";
import { SectionsProvider } from "./context/SectionsContext";
import { PurchasesProvider } from "./context/PurchasesContext";
import { UserProvider } from "./context/UserContext";
import { VideosProvider } from "./context/VideosContext";
import { VideoTypeProvider } from "./context/VideoTypeContext";
import Main from "./Main";
import { InvoicesProvider } from "./context/InvoicesContext";

function App() {
  return (
    <ModalProvider>
      <UserProvider>
        <CoursesProvider>
          <SectionsProvider>
            <LessonsProvider>
              <MetodosProvider>
                <CheckoutProvider>
                  <PurchasesProvider>
                    <VideosProvider>
                      <FiltersProvider>
                        <VideoTypeProvider>
                          <ClassCategoryProvider>
                            <InvoicesProvider>
                              <Main />
                            </InvoicesProvider>
                          </ClassCategoryProvider>
                        </VideoTypeProvider>
                      </FiltersProvider>
                    </VideosProvider>
                  </PurchasesProvider>
                </CheckoutProvider>
              </MetodosProvider>
            </LessonsProvider>
          </SectionsProvider>
        </CoursesProvider>
      </UserProvider>
    </ModalProvider>
  );
}

export default App;
