import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import StatusBadge from "../components/common/StatusBadge";
import { S3_ENDPOINT } from "../utils";
import { Link } from "@reach/router";
import moment from "moment";

const ThankYou = ({ purchase_id }) => {
  const { purchase, getSinglePurchase } = useContext(PurchasesContext);

  useEffect(() => {
    let purchaseInterval = setInterval(() => {
      getSinglePurchase(purchase_id);
    }, 1000);
    return () => {
      clearInterval(purchaseInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCompra = () => {
    if (purchase && purchase !== null) {
      if (purchase.course_purchase && purchase.course_purchase !== null) {
        if (
          ["success", "active", "complete", "completed"].includes(
            purchase.status
          )
        ) {
          if (purchase.course_purchase.course_id === 2) {
            if (purchase.payment_method_id === 3) {
              return (
                <img
                  alt="thank you"
                  src={`${S3_ENDPOINT}/instructor-recibo-pagos.png`}
                  className="mw-100 w-100 d-block m-auto"
                />
              );
            }
            return (
              <img
                alt="thank you"
                src={`${S3_ENDPOINT}/instructor-recibo-contado.png`}
                className="mw-100 w-100 d-block m-auto"
              />
            );
          }
          if (purchase.course_purchase.course_id === 3) {
            if (purchase.payment_method_id === 3) {
              return (
                <img
                  alt="thank you"
                  src={`${S3_ENDPOINT}/certi-recibo-pagos.png`}
                  className="mw-100 w-100 d-block m-auto"
                />
              );
            }
            return (
              <img
                alt="thank you"
                src={`${S3_ENDPOINT}/certi-recibo-contado.png`}
                className="mw-100 w-100 d-block m-auto"
              />
            );
          }
          if (purchase.course_purchase.course_id === 3) {
            return (
              <img
                alt="thank you"
                src={`${S3_ENDPOINT}/recibo-certi-3.png`}
                className="mw-100 w-100 d-block m-auto"
              />
            );
          }
        }
      }
      return (
        <div className="card no-scale my-3">
          <h1 className="h2">Resumen</h1>
          <h4>Compra #{purchase.purchase_id}</h4>
          <p>Fecha: {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}</p>
          <p>Total: ${purchase.amount} MXN</p>
          <p>
            Estado:{" "}
            <StatusBadge status={purchase.status} order_id={purchase.orderid} />
          </p>
          <p>
            *Si el estado sigue pendiente después de 1 minuto, quiere decir que
            hubo un error con tu pago.
          </p>
          <p>
            Expira en:{" "}
            {moment(purchase.expiration_date).format("DD MMM YYYY HH:mm")}
          </p>
          <Link to="/mylatina" className="btn btn-dark">
            Ir a Mi Panel
          </Link>
        </div>
      );
    }
    return <div className="spinner-border text-white"></div>;
  };

  return (
    <div className="container-fluid py-3 bg-dark vh-100">
      <div className="container mw-500 my-5">{renderCompra()}</div>
    </div>
  );
};

export default ThankYou;
