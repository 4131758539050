import { Link } from "@reach/router";
import React, { useContext } from "react";
import { SectionsContext } from "../../context/SectionsContext";
import SectionThumbnail from "../sections/SectionThumbnail";

const BottomNavbar = () => {
  const { sections } = useContext(SectionsContext);

  const renderSections = () => {
    if (Array.isArray(sections)) {
      const cols = 12 / sections.length;
      return sections
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map((section) => (
          <div key={section.section_id} className={`col-${cols}`}>
            <Link
              to={`./sections/${section.section_id}`}
              className="no-decoration"
            >
              <SectionThumbnail
                name={section.name}
                file={
                  section.course_id === 4
                    ? { name: `Icon-${String(section.name)}`, type: "png" }
                    : section.file
                }
              />
            </Link>
          </div>
        ));
    }
  };

  return (
    <div className="bottom-navbar bg-gray py-2">
      <div className="row">{renderSections()}</div>
    </div>
  );
};

export default BottomNavbar;
