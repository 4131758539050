import React from "react";
import { S3_ENDPOINT } from "../../utils";

const SectionInfo = ({ section }) => {
  const renderImage = () => {
    const { file } = section;
    if (file && file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
          className="info-img"
        />
      );
    }
  };

  return (
    <div className="row">
      <div className="container-fluid px-0">{renderImage()}</div>
    </div>
  );
};

export default SectionInfo;
