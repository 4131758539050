import React from "react";
import { S3_ENDPOINT } from "../utils";
import { Link } from "@reach/router";

const Loading = () => {
  return (
    <div className="container-fluid bg-dark vh-100">
      <div className="row vh-100 align-items-center">
        <div className="container-fluid text-center">
          <img
            alt="logo certification"
            src={`${S3_ENDPOINT}/latina-cert-logo.png`}
            className="logo-footer d-block m-auto"
          />
          <div className="spinner-border text-white my-3" />
          <Link to="/" className="d-block m-auto text-white no-decoration my-3">
            Ir a Inicio
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Loading;
