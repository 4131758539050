import React, { useContext } from "react";
import { LessonsContext } from "../../context/LessonsContext";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";

const LessonCard = ({ currentLesson }) => {
  const { lesson } = useContext(LessonsContext);

  const isComplete = () => {
    return (
      currentLesson.completed_lesson && currentLesson.completed_lesson !== null
    );
  };

  const isSelected = () => {
    if (currentLesson !== null && lesson !== null) {
      return currentLesson.lesson_id === lesson.lesson_id;
    }
  };

  const renderImage = () => {
    let { media, file } = currentLesson;
    if (file && file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
          className="mw-100 w-100 course-img"
        />
      );
    }
    if (media) {
      if (Array.isArray(media)) {
        media = media
          .filter((media) => media.file !== null)
          .find((media) =>
            ["jpg", "png", "jpeg", "gif"].includes(media.file.type)
          );
        if (!media) {
          media = null;
        }
      }
      if (media !== null && !Array.isArray(media)) {
        let { file } = media;
        return (
          <img
            src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
            className="mw-100 w-100 course-img"
          />
        );
      }
    }
    return <div className="course-img"></div>;
  };

  const renderInstructor = () => {
    if (currentLesson.instructor && currentLesson.instructor !== null) {
      return currentLesson.instructor.name;
    }
  };

  return (
    <Link
      className={`col-lg-4 col-md-6 col-12 pb-3 hover-accent text-dark no-decoration ${
        isSelected() ? "bg-accent" : ""
      }`}
      to={`./lessons/${currentLesson.lesson_id}`}
    >
      <div className="card px-0 py-0 border lesson-card no-scale shadow-sm border">
        {renderImage()}
        <div className="course-meta p-3">
          <h3 className="lesson-title bold">
            {isComplete() && <i className="fa fa-check"></i>}
            {currentLesson.name}
          </h3>
          <div className="row">
            <div className="col-12 col-md-6">
              <span className="me-3">{renderInstructor()}</span>
            </div>
            <div className="col-12 col-md-6 text-end">
              <span className="me-3">
                <p className="mb-0">
                  <span
                    className={`badge badge-pill ${
                      String(currentLesson.level).toLowerCase() ===
                      "principiante"
                        ? "bg-blue text-dark"
                        : "bg-warning"
                    }`}
                  >
                    {currentLesson.level}
                  </span>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default LessonCard;
