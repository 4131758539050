import React, { useContext, useState } from "react";
import { FiltersContext } from "../../context/FiltersContext";
import FilterForm from "./FilterForm";

const FilterSection = () => {
  const {
    level,
    query,
    author,
    values,
    setFilter,
    showFavorites,
    instructor_id,
  } = useContext(FiltersContext);

  const filters = {
    level,
    query,
    author,
    showFavorites,
    instructor_id,
  };

  return (
    <div className="filter-card card no-scale shadow-sm my-3">
      <FilterForm filters={filters} values={values} setFilter={setFilter} />
    </div>
  );
};

export default FilterSection;
