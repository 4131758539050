import { Link } from "@reach/router";
import React, { useEffect } from "react";
import Footer from "../components/global/Footer";
import Navbar from "../components/global/Navbar";
import { S3_ENDPOINT } from "../utils";
import "./Home.css";

const LatinaInstructor = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div id="instructor" className="home-page bg-dark">
      <Navbar />
      <div id="video" className="row video-row">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="d-block slideshow-img w-100 mw-100"
                src={`${S3_ENDPOINT}/landing-instructor-cover.jpg`}
                alt="First slide"
              />
              <div className="slideshow-text pt-5">
                <div className="row h-100">
                  <div className="col-12 col-md-6">
                    <div className="row h-600 align-items-center">
                      <div className="slideshow-content">
                        <img
                          id="instructor-logo"
                          alt="logo instructor"
                          src={`${S3_ENDPOINT}/instructor-19.png`}
                          className="mb-4"
                        />
                        <div className="text-lg">
                          <p className="bold mb-0">
                            ¡Conviértete en instructora del método Latina
                            Tribe&copy;!
                          </p>
                          <p>
                            Emprende tu propio negocio y empodera a tu comunidad
                            a través de tu pasión por el baile.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            {/*<a
                              target="_blank"
                              href={"https://forms.gle/waHbhXw5pEWqoJrK6"}
                              className="btn btn-primary btn-lg mb-3 w-100 bold"
                            >
                              SIGN UP NOW
  </a>*/}
                          </div>
                          <div className="col-12 col-md-6"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 h-600 hide-mobile">
                    <div className="row h-600 align-items-center">
                      <video
                        className="mw-100"
                        poster={`${S3_ENDPOINT}/landing-instructor-cover.jpg`}
                        controls
                      >
                        <source
                          type="video/mp4"
                          src="https://latinacerts.s3.us-west-1.amazonaws.com/trailer-instructor.mp4"
                        />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <section id="instructor" className="py-5">
        <div className="container-fluid">
          <h2 className="text-primary">WHAT TO EXPECT</h2>
          <div className="row">
            <div className="col-12 col-md-4 mt-5">
              <div className="card text-start">
                <img
                  alt="que incluye"
                  src={`${S3_ENDPOINT}/landing-instructor-1.jpg`}
                  className="mw-100 w-100 m-auto my-0 d-block"
                />
                <div className="p-3">
                  <h3 className="text-primary">¿QUÉ INCLUYE?</h3>
                  <ul>
                    <li>
                      Curso intensivo, teórico, práctico y presencial de 3 días
                      en Latina Studio Mty
                    </li>
                    <li>Instructor kit + manual de instructora</li>
                    <li>Glam station + photo {"&"} video content</li>
                    <li>Acceso a exclusive Latina Lifestyle merch</li>
                    <li>Masterterclass con founders</li>
                    <li>diploma</li>
                    <li>Tu primera mensualidad al portal</li>
                    <li>¡Y mucho más!</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-5">
              <div className="card text-start">
                <img
                  alt="que vas a aprender"
                  src={`${S3_ENDPOINT}/landing-instructor-2.jpg`}
                  className="mw-100 w-100 m-auto my-0 d-block"
                />
                <div className="p-3">
                  <h3 className="text-primary">¿QUÉ VAS A APRENDER?</h3>
                  <ul>
                    <li>Metodología Latina Tribe.</li>
                    <li>
                      Técnica, combos y coreografías originales del método
                      Latina Tribe.
                    </li>{" "}
                    <li>Ritmo y factores del movimiento.</li>
                    <li>La magia del baile en la salud mental.</li>
                    <li>
                      Como enseñar y como aprender coreografías Uso del portal
                      de instructoras.
                    </li>
                    <li>Latina Tribe Brand Guidelines.</li>
                    <li>Creación de playlist y estructura de clase.</li>
                    <li>
                      El rol de la instructora The look, the vibe {"&"} the
                      energy of a Latina Instructor Como crear un warm up {"&"}{" "}
                      cool down ¡Y mucho más!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-5">
              <div className="card text-start">
                <img
                  alt="requisitos"
                  src={`${S3_ENDPOINT}/landing-instructor-3.jpg`}
                  className="mw-100 w-100 m-auto my-0 d-block"
                />
                <div className="p-3">
                  <h3 className="text-primary">¿CUÁLES SON LOS REQUISITOS?</h3>
                  <ol>
                    <li>
                      Llenar formulario de postulación (fecha límite 6 Mayo
                      2024).{" "}
                      {/*<a
                        target="_blank"
                        href={"https://forms.gle/waHbhXw5pEWqoJrK6"}
                        className="btn btn-primary mb-3 bold"
                      >
                        LLENAR FORMULARIO
                      </a>*/}
                    </li>
                    <li>Ser mayor de edad +18</li>
                    <li>Ser mujer o LGBTQ+</li>
                    <li>
                      Contar con conocimientos básicos de la danza (general).
                    </li>
                    <li>
                      Liderazgo, full bichota energy ¡y muchas ganas de formar
                      parte de la tribe de mujeres que están cambiando el mundo!
                    </li>
                  </ol>
                  <p>
                    *El proceso de selección estará sujeto a los cupos
                    disponibles por ciudad.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LatinaInstructor;
