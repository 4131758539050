import React, { useContext, useReducer } from "react";
import { createContext } from "react";
import AdjuntosService from "../services/AdjuntosService";
import {
  CREATE_LESSON,
  SET_PROPERTY_LESSON,
  SET_LESSON,
  LESSONS_RECIBIDAS,
  AGREGAR_MULTIMEDIA,
  AGREGAR_DESCARGABLE,
  ELIMINAR_DESCARGABLE,
  ELIMINAR_MULTMEDIA,
  SET_PROPERTY_MULTIMEDIA,
  SET_PROPERTY_DESCARGABLE,
  APPEND_LESSONS,
} from "../types/lessons";
import { SHOW_SPINNER, HIDE_SPINNER } from "../types";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import { hideModal } from "../utils";
import Logro from "../components/global/Logro";
import LessonsReducer from "../reducers/LessonsReducer";
import LessonsService from "../services/LessonsService";
import PlaybackService from "../services/PlaybackService";

const initialState = {
  lessons: null,
  lesson: null,
};

export const LessonsContext = createContext(initialState);

export const LessonsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LessonsReducer, initialState);

  const { success, modalComponent, toggleConfetti } = useContext(ModalContext);

  const getSingleLesson = (lesson_id) => {
    LessonsService.getSingleLesson(lesson_id).then((res) => {
      const { lesson } = res.data;
      dispatch({ type: SET_LESSON, payload: lesson });
    });
  };

  const getSectionLessons = (section_id) => {
    LessonsService.getSectionLessons(section_id).then((res) => {
      const { lessons } = res.data;
      dispatch({ type: LESSONS_RECIBIDAS, payload: lessons });
    });
  };

  const getSiguienteLesson = (lesson_id, course_id) => {
    LessonsService.getSiguienteLesson(lesson_id, course_id).then((res) => {
      const { lesson_id, course_id } = res.data;
      if (lesson_id) {
        navigate("../");
        navigate(`/courses/${course_id}/lesson/${lesson_id}`);
      } else {
        navigate(`/courses/${course_id}`);
      }
    });
  };

  const clearLessons = () => {
    dispatch({ type: LESSONS_RECIBIDAS, payload: null });
  };

  const clearLesson = () => {
    dispatch({ type: SET_LESSON, payload: null });
  };

  const getLessons = ({ course_id, section_id }) => {
    LessonsService.getLessons({
      course_id,
      section_id,
    }).then((res) => {
      const { lessons } = res.data;
      dispatch({ type: APPEND_LESSONS, payload: lessons });
    });
  };

  const selectLesson = (lesson) => {
    dispatch({ type: SET_LESSON, payload: lesson });
  };

  const sortLessons = (lessons) => {
    lessons = lessons.map((lesson, index) => ({
      ...lesson,
      orden: index + 1,
    }));
    dispatch({ type: LESSONS_RECIBIDAS, payload: lessons });
  };

  const setPropiedadLesson = (key, value) => {
    dispatch({ type: SET_PROPERTY_LESSON, payload: { key, value } });
  };

  const createLesson = () => {
    dispatch({ type: CREATE_LESSON });
  };

  const postLesson = (lesson) => {
    dispatch({ type: SHOW_SPINNER });
    if (isNaN(lesson.lesson_id)) {
      const promises = [];
      let multimedia = [];
      let descargables = [];
      lesson.multimedia.forEach(({ file }) => {
        if (file && file !== null) {
          const promise = new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("adjunto", file);
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { idAdjunto } = res.data;
              multimedia.push({ idAdjunto });
              resolve();
            });
          });
          promises.push(promise);
        }
      });
      lesson.descargables.forEach(({ file }) => {
        if (file && file !== null) {
          const promise = new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("adjunto", file);
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { idAdjunto } = res.data;
              descargables.push({ idAdjunto });
              resolve();
            });
          });
          promises.push(promise);
        }
      });
      if (lesson.idAdjunto !== null && typeof lesson.idAdjunto === "object") {
        const promiseAdjunto = new Promise((resolve, reject) => {
          let formData = new FormData();
          formData.append("adjunto", lesson.idAdjunto);
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { idAdjunto } = res.data;
            lesson.idAdjunto = idAdjunto;
            resolve();
          });
        });
        promises.push(promiseAdjunto);
      }
      Promise.all(promises).then(() => {
        lesson.multimedia = multimedia.filter(
          (mult) =>
            (mult.src && mult.src !== "") ||
            (mult.idAdjunto && mult.idAdjunto !== null)
        );
        lesson.descargables = descargables.filter(
          (descargable) =>
            (descargable.src && descargable.src !== "") ||
            (descargable.idAdjunto && descargable.idAdjunto !== null)
        );
        LessonsService.postLesson(lesson).then(() => {
          dispatch({ type: HIDE_SPINNER });
          navigate("../../");
          success("¡Lesson guardada con éxito!");
        });
      });
    } else {
      const promises = [];
      let multimedia = [];
      let descargables = [];
      lesson.multimedia.forEach((media) => {
        if (media.file && media.file !== null) {
          const promise = new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("adjunto", media.file);
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { idAdjunto } = res.data;
              multimedia.push({ idMultimedia: media.idMultimedia, idAdjunto });
              resolve();
            });
          });
          promises.push(promise);
        }
      });
      lesson.descargables.forEach((descargable) => {
        if (descargable.file && descargable.file !== null) {
          const promise = new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("adjunto", descargable.file);
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { idAdjunto } = res.data;
              descargables.push({
                idDescargable: descargable.idDescargable,
                idAdjunto,
              });
              resolve();
            });
          });
          promises.push(promise);
        }
      });
      if (lesson.file && lesson.file !== null) {
        const adjuntoPromise = new Promise((resolve, reject) => {
          let formData = new FormData();
          formData.append("adjunto", lesson.file);
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { idAdjunto } = res.data;
            lesson.idAdjunto = idAdjunto;
            resolve();
          });
        });
        promises.push(adjuntoPromise);
      }
      Promise.all(promises).then(async () => {
        lesson.multimedia = lesson.multimedia
          .filter(
            (mult) =>
              (mult.src && mult.src !== "") ||
              (mult.idAdjunto && mult.idAdjunto !== null)
          )
          .concat(multimedia);
        lesson.descargables = lesson.descargables
          .filter(
            (descargable) =>
              (descargable.src && descargable.src !== "") ||
              (descargable.idAdjunto && descargable.idAdjunto !== null)
          )
          .concat(descargables);
        LessonsService.putLesson(lesson).then(() => {
          navigate("../../");
          dispatch({ type: HIDE_SPINNER });
          success("¡Lesson guardada con éxito!");
        });
      });
    }
  };

  const agregarMultimediaLesson = () => {
    dispatch({ type: AGREGAR_MULTIMEDIA });
  };

  const eliminarMultimedia = (multimedia) => {
    dispatch({ type: ELIMINAR_MULTMEDIA, payload: multimedia });
  };

  const eliminarDescargable = (descargable) => {
    dispatch({ type: ELIMINAR_DESCARGABLE, payload: descargable });
  };

  const agregarDescargableLesson = () => {
    dispatch({ type: AGREGAR_DESCARGABLE });
  };

  const setPropiedadMultimedia = (idMultimedia, key, value) => {
    dispatch({
      type: SET_PROPERTY_MULTIMEDIA,
      payload: { idMultimedia, key, value },
    });
  };

  const setPropiedadDescargable = (idDescargable, key, value) => {
    dispatch({
      type: SET_PROPERTY_DESCARGABLE,
      payload: { idDescargable, key, value },
    });
  };

  const eliminarLesson = (lesson_id) => {
    LessonsService.deleteLesson(lesson_id).then(() => {
      success("¡Lección eliminada con éxito!");
      navigate("../../");
      hideModal();
    });
  };

  const completeLesson = (lesson_id) => {
    LessonsService.completeLesson(lesson_id).then(() => {
      success("¡Felicidades! Completaste esta lección.");
      getSingleLesson(lesson_id);
    });
  };

  const uncompleteLesson = (lesson_id) => {
    LessonsService.uncompleteLesson(lesson_id).then(() => {
      getSingleLesson(lesson_id);
    });
  };

  const updateOrdenLessons = (lessons) => {
    LessonsService.putOrdenLessons(lessons);
  };

  const terminarReproduccion = (lesson_id) => {
    PlaybackService.terminarReproduccion(lesson_id);
  };

  const playLesson = () => {
    dispatch({ type: SHOW_SPINNER });
    PlaybackService.playLesson()
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        const { reproduccion } = res.data;
        const { course_id, section_id, idTema, lesson_id } = reproduccion;
        navigate(
          `/courses/${course_id}/sections/${section_id}/temas/${idTema}/lessons/${lesson_id}`
        );
      })
      .catch(() => {
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const addFavorite = (lesson_id) => {
    LessonsService.addFavorite(lesson_id).then(() => {
      success("Agregado a favoritos.");
      getSingleLesson(lesson_id);
    });
  };

  const removeFavorite = (lesson_id) => {
    LessonsService.removeFavorite(lesson_id).then(() => {
      success("Eliminado de favoritos.");
      getSingleLesson(lesson_id);
    });
  };

  return (
    <LessonsContext.Provider
      value={{
        ...state,
        playLesson,
        postLesson,
        getLessons,
        addFavorite,
        sortLessons,
        clearLesson,
        createLesson,
        clearLessons,
        selectLesson,
        eliminarLesson,
        removeFavorite,
        completeLesson,
        getSingleLesson,
        uncompleteLesson,
        getSectionLessons,
        updateOrdenLessons,
        setPropiedadLesson,
        getSiguienteLesson,
        eliminarMultimedia,
        eliminarDescargable,
        terminarReproduccion,
        setPropiedadMultimedia,
        setPropiedadDescargable,
        agregarMultimediaLesson,
        agregarDescargableLesson,
      }}
    >
      {children}
    </LessonsContext.Provider>
  );
};
