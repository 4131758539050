import React, { useContext, useEffect } from "react";
import LessonContent from "../components/lessons/LessonContent";
import LessonInfo from "../components/lessons/LessonInfo";
import LessonMedia from "../components/lessons/LessonMedia";
import LessonResources from "../components/lessons/LessonResources";
import { LessonsContext } from "../context/LessonsContext";

const SingleLesson = ({ lesson_id }) => {
  const {
    lesson,
    clearLesson,
    addFavorite,
    removeFavorite,
    completeLesson,
    getSingleLesson,
    uncompleteLesson,
  } = useContext(LessonsContext);

  useEffect(() => {
    getSingleLesson(lesson_id);
    return clearLesson;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson_id]);

  const toggleComplete = () => {
    if (lesson.completed.length > 0) {
      return uncompleteLesson(lesson_id);
    }
    completeLesson(lesson_id);
  };

  const toggleFavorite = () => {
    if (lesson.favorites.length > 0) {
      return removeFavorite(lesson_id);
    }
    addFavorite(lesson_id);
  };

  const renderMedia = () => {
    if (lesson && lesson !== null) {
      if (Array.isArray(lesson.media)) {
        if (lesson.media.length > 0) {
          return <LessonMedia lesson={lesson} />;
        }
      }
    }
  };

  const renderResources = () => {
    if (lesson && lesson !== null) {
      if (Array.isArray(lesson.resources)) {
        if (lesson.resources.length > 0) {
          return <LessonResources lesson={lesson} />;
        }
      }
    }
  };

  const renderContent = () => {
    if (lesson && lesson !== null) {
      if (lesson.content !== "" && lesson.content !== null) {
        return <LessonContent lesson={lesson} />;
      }
    }
  };

  return (
    <div className="container-fluid px-0 lesson-container">
      <LessonInfo
        lesson={lesson}
        toggleComplete={toggleComplete}
        toggleFavorite={toggleFavorite}
        completeLesson={completeLesson}
      />
      {renderContent()}
      {renderMedia()}
      {renderResources()}
    </div>
  );
};

export default SingleLesson;
