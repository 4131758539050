import React from "react";
import SingleMedia from "../media/SingleMedia";

const LessonMedia = ({ lesson }) => {
  const renderMedia = () => {
    if (Array.isArray(lesson.media)) {
      return lesson.media.map((media) => (
        <SingleMedia key={media.lesson_media_id} media={media} />
      ));
    }
  };
  return (
    <div className="card p-2 shadow-sm no-scale border mb-3">
      <div className="row">{renderMedia()}</div>
    </div>
  );
};

export default LessonMedia;
