import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import UserData from "../components/user/UserData";
import UserForm from "../components/user/UserForm";

const MiInformacion = () => {
  const [editMode, setEditMode] = useState(false);

  const { user, signOut, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(UserContext);

  const handleSubmit = (e) => {
    setEditMode(false);
    e.preventDefault();
    updateUsuario(user);
  };

  const handleCancel = () => {
    setEditMode(false);
    getUsuario();
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const renderInformacion = () => {
    if (editMode) {
      return (
        <UserForm
          user={user}
          setPropiedadUser={setPropiedadUser}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      );
    }
    return <UserData user={user} signOut={signOut} handleEdit={handleEdit} />;
  };

  return (
    <div className="container-fluid py-4">
      <h1 className="mb-4">Mi Información</h1>
      <div className="card p-3 shadow-sm no-scale">{renderInformacion()}</div>
    </div>
  );
};

export default MiInformacion;
