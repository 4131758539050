import React from "react";
import { Link } from "@reach/router";
import SectionThumbnail from "./SectionThumbnail";

const SectionCard = ({ section }) => {
  return (
    <Link
      to={section.link ? section.link : `./sections/${section.section_id}`}
      className="cursor-pointer text-white no-decoration"
    >
      <div className="container-fluid px-0 py-3 border-bottom pe-2">
        <div className="row mx-0 align-items-center">
          {section.course_id === 4 && (
            <div className="col-2 text-center">
              <SectionThumbnail
                file={{
                  name: `Icon-${String(
                    section.icon ? section.icon : section.name
                  )}`,
                  type: "png",
                }}
              />
            </div>
          )}
          <div className={`col-${section.course_id === 4 ? "10" : "12"} px-4`}>
            <h5 className="mb-0">{section.name}</h5>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SectionCard;
