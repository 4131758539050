import api from "./api";

const route = "/checkout";

export default {
  attempt: ({
    course_id,
    discountCode,
    payment_method,
    payment_source_id,
    installments,
  }) =>
    api.post(`${route}`, {
      course_id,
      discountCode,
      installments,
      payment_method,
      payment_source_id,
    }),
  getCourseCheckout: (course_id) => api.get(`${route}/${course_id}`),
  postCheckout: (course_id, payment_source_id, discountCode) =>
    api.post(route, {
      course_id,
      payment_source_id,
      discountCode,
    }),
  postPayPal: (course_id, discountCode) =>
    api.post(`${route}/paypal`, { course_id, discountCode }),
  capturePayPal: ({ orderID }) => api.post(`${route}/capture`, { orderID }),
};
