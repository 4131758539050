import { navigate } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { LessonsContext } from "../../context/LessonsContext";
import { SectionsContext } from "../../context/SectionsContext";
import SectionLessons from "../lessons/SectionLessons";
import FilterSection from "../videos/FilterSection";
import SectionInfo from "./SectionInfo";

const SingleSection = ({ course_id, section_id }) => {
  const { section, getSingleSection, clearSingleSection } =
    useContext(SectionsContext);
  const { clearLessons } = useContext(LessonsContext);

  useEffect(() => {
    handleClear();
    getSingleSection(section_id);
    return handleClear;
  }, [section_id]);

  useEffect(() => {
    if (section !== null) {
      const { lessons } = section;
      if (Array.isArray(lessons)) {
        if (lessons.length === 1) {
          const lesson = lessons[0];
          if (lesson) {
            navigate(`./${section_id}/lessons/${lesson.lesson_id}`);
          }
        }
      }
    }
  }, [section]);

  const handleClear = () => {
    clearLessons();
    clearSingleSection();
  };

  const renderFilters = () => {
    if (section.section_id === 4) {
      return <FilterSection />;
    }
  };

  const renderContent = () => {
    if (section && section !== null) {
      return (
        <div>
          <SectionInfo section={section} />
          {renderFilters()}
          <SectionLessons course_id={course_id} section_id={section_id} />
        </div>
      );
    }
  };

  return (
    <div className="container-fluid overflow-x-hidden px-2">
      {renderContent()}
    </div>
  );
};

export default SingleSection;
