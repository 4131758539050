import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../utils";
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import moment from "moment";

const instructorMessage = "Tu membresía de instructora se encuentra inactiva.";

const genericMessage = "No cuentas con acceso a este contenido.";

const Sorry = ({ course_id }) => {
  const [message, setMessage] = useState("");
  const { user } = useContext(UserContext);

  useEffect(() => {
    setMessage(parseInt(course_id) === 4 ? instructorMessage : genericMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user !== null && parseInt(course_id) === 4) {
      let purchases = user.purchases.filter(
        (purchase) =>
          purchase.course_purchase && purchase.course_purchase !== null
      );
      purchases = purchases.sort((a, b) =>
        moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1
      );
      let current = purchases.find(
        ({ course_purchase }) =>
          parseInt(course_purchase.course_id) === parseInt(course_id)
      );
      if (current) {
        setMessage(
          "Tu membresía se encuentra inactiva porque tu último pago no fue exitoso."
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="container-fluid bg-dark text-center">
      <div className="row vh-100 align-items-center">
        <div className="container-fluid">
          <img
            alt="logo certification"
            src={`${S3_ENDPOINT}/latina-cert-logo.png`}
            className="mw-50 w-50 d-block m-auto"
          />
          <p className="text-white h3 my-3">Lo siento bebé. {message}</p>
          <Link to={`/checkout/course/${course_id}`} className="btn bg-pink">
            Activar Acceso
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sorry;
