import api from "./api";

const route = "/lessons";

export default {
  getSingleLesson: (lesson_id) => api.get(`${route}/${lesson_id}`),
  getSectionLessons: (section_id) => api.get(`${route}/section/${section_id}`),
  completeLesson: (lesson_id) => api.post(`${route}/complete`, { lesson_id }),
  uncompleteLesson: (lesson_id) => api.delete(`${route}/complete/${lesson_id}`),
  addFavorite: (lesson_id) => api.post(`${route}/favorite`, { lesson_id }),
  removeFavorite: (lesson_id) => api.delete(`${route}/favorite/${lesson_id}`),
};
