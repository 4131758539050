import React from "react";

const CheckoutInstallmentCard = ({
  label,
  selected,
  description,
  setSelected,
  installments,
}) => {
  const isSelected = selected === installments;
  return (
    <label className="d-block mb-3">
      <input
        type="radio"
        checked={isSelected}
        className="me-3"
        onChange={(e) => setSelected(installments)}
      />
      {label}
      <p className="small">{description}</p>
    </label>
  );
};

export default CheckoutInstallmentCard;
