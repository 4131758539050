import React, { useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { cardStyle, handleCheckoutError } from "../../utils";
import CheckoutService from "../../services/CheckoutService";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";
import { UserContext } from "../../context/UserContext";
import CheckoutInstallmentCard from "../checkout/CheckoutInstallmentCard";

const StripeCreditCard = ({
  element_id,
  discountCode,
  installmentsOptions,
}) => {
  const [installments, setInstallments] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { user } = useContext(UserContext);

  const { alert, success } = useContext(ModalContext);

  const handleError = (message) => {
    setProcessing(false);
    setDisabled(false);
    alert(message);
  };

  const handleSuccess = (purchase_id) => {
    setProcessing(false);
    setDisabled(false);
    navigate(`/thankyou/${purchase_id}`);
  };

  const handleAttempt = async (res, card) => {
    let installmentsObject = getInstallmentsObject(installments);
    const { clientSecret, purchase_id } = res.data;
    if (clientSecret) {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
        },
        ...installmentsObject,
      });
      if (payload.error) {
        console.log("error");
        handleError(payload.error.message);
      } else {
        handleSuccess(purchase_id);
      }
    } else {
      handleSuccess(purchase_id);
    }
  };

  const getInstallmentsObject = (installments) => {
    if (installments > 1) {
      return {
        payment_method_options: {
          card: {
            installments: {
              plan: {
                count: installments,
                interval: "month",
                type: "fixed_count",
              },
            },
          },
        },
      };
    }
    return {};
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const card = elements.getElement(CardElement);
    const payment_method = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        email: user.email,
      },
    });
    CheckoutService.attempt({
      course_id: element_id,
      discountCode,
      payment_method,
      installments,
    })
      .then((res) => handleAttempt(res, card))
      .catch((error) => handleCheckoutError(error, handleError));
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
  };

  const renderInstallmentOptions = () => {
    if (Array.isArray(installmentsOptions)) {
      return (
        <div className="mt-4">
          <p className="bold">Plan de Pagos</p>
          {installmentsOptions.map((option, index) => (
            <CheckoutInstallmentCard
              key={index}
              {...option}
              selected={installments}
              setSelected={setInstallments}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <CardElement
          id="card-element"
          options={cardStyle}
          className="form-control pt-2 my-2"
          onChange={handleChange}
        />
        {renderInstallmentOptions()}
        <button
          className="btn btn-primary bold mt-3"
          disabled={processing || disabled}
        >
          {processing ? <div className="spinner-border"></div> : "Pagar Ahora"}
        </button>
      </form>
    </div>
  );
};

export default StripeCreditCard;
