import React, { useContext, useState } from "react";
import CreditCardInput from "react-credit-card-input";
import { MetodosContext } from "../../context/MetodosContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_KEY } from "../../utils";
import Switch from "react-switch";
import StripeCardForm from "./StripeCardForm";

const promise = loadStripe(STRIPE_KEY);

const FormCreditCard = ({ modifier, saveCard }) => {
  const [recurring, setRecurring] = useState(false);
  const [nombre, setNombre] = useState("");
  const [number, setNumber] = useState("");
  const [expiry, setExpiry] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [cvc, setCvc] = useState("");

  const { spinner, postPaymentSource } = useContext(MetodosContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (recurring) {
      return setSubmitted(true);
    }
    modifier(nombre, number, expiry, cvc);
  };

  const renderForm = () => {
    if (recurring) {
      return (
        <Elements stripe={promise}>
          <StripeCardForm submitted={submitted} modifier={postPaymentSource} />
        </Elements>
      );
    }
    return (
      <div>
        <label>Nombre en la Tarjeta</label>
        <input
          type="text"
          className="form-control mb-3"
          style={{ maxWidth: 409 }}
          onChange={(e) => setNombre(e.target.value)}
        />
        <CreditCardInput
          cardNumberInputProps={{
            value: number,
            onChange: (e) => setNumber(e.target.value),
          }}
          cardExpiryInputProps={{
            value: expiry,
            onChange: (e) => setExpiry(e.target.value),
          }}
          cardCVCInputProps={{
            value: cvc,
            onChange: (e) => setCvc(e.target.value),
          }}
          fieldClassName="input"
        />
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row align-items-center mb-4">
        <div className="col-2">
          <Switch
            checked={recurring}
            onChange={(checked) => setRecurring(checked)}
          />
        </div>
        <div className="col-10">¿Usar para pagos Recurrentes?</div>
      </div>
      {renderForm()}
      <div className="container-fluid px-0 mt-3">
        <button
          type="submit"
          className="btn btn-dark btn-block"
          disabled={spinner}
        >
          {spinner ? (
            <div className="spinner-border"></div>
          ) : saveCard ? (
            "Guardar"
          ) : (
            "Pagar Ahora"
          )}
        </button>
      </div>
    </form>
  );
};

export default FormCreditCard;
